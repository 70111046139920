.main-button {
    padding: 12px 20px;
    display: block;
    width: fit-content;
    color: #FFF !important;
    border: 0;
    font-size: 19px;
    border-radius: 5px;
    letter-spacing: 0.12px;
    max-height: 50px;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
}

button {
    font-family: Outfit-Regular, serif;
    cursor: pointer;

}

.secondary-button {
    padding: 9px 14px;
    display: block;
    white-space: nowrap;
    text-decoration: none;
    width: fit-content;
    cursor: pointer;
    max-height: 39px;

    color: #FFF !important;
    border: 0;
    font-size: 17px;
    border-radius: 5px;
}

.orange {
    background-color: #f6a723;
}

.aqua {
    background-color: #428fae;
}

.blue {
    background-color: #334c6e;
}

.black {
    background-color: #3d3d3d;
}

.white {
    background-color: #ffffff;
    border: 0.6px solid #d3d9e1;
    color: #334c6e !important;
}

.red {
    background-color: #d30000 !important;
}

.orange:hover {
    background-color: #f59900;
}

.aqua:hover {
    background-color: #2a81a4;
}

.blue:hover {
    background-color: #334c6e;
}

.black:hover {
    background-color: #000000;
}

.white:hover {
    background-color: #f6f6f6;
    border: 0.6px solid #91bed0;
}

.step-arrow {
    width: 12px;
    height: 15px;
    margin-left: 40%;
}

@media only screen and (max-width: 768px) {
    .main-button {
        font-size: 24px;
        padding: 12px 5%;
        line-height: 24px;
    }
}
