.addColleagueItemRow {
  display: flex;
  width:60vw;
  margin-left: 5vw;
  justify-content: space-between;
}
.addColleagueFlex {
  display: flex;
}
.addColleagueHeader {
  margin-left: 5vw;
  margin-right: auto;
  text-align: left;
}
.addColleagueHeaderTitle{
  font-family: 'NeoTech', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 1vh;
}
.addColleagueHeaderSubTitle {
  margin-top: 0;
  font-size: 1.2em;
}
.addColleagueNextPage {
  float:right;
  margin-top: 2vh;

  width: 9vw;
  border-radius: 10px;
  height:5.5vh;
  border: 0;
  font-size: 1.5em;
  font-family: 'NeoTech', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  color: white;
  background-color: #FAA634;
}

.noLoginNextPage {
  margin-top: 2vh;
  cursor: pointer;
  width: 12vw;
  border-radius: 10px;
  height:5.5vh;
  border: 0;
  font-size: 1.5em;
  font-family: 'NeoTech', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  color: white;
  background-color: #FAA634;
}

.shoppingCartItemButton{
  background-color: #F2F2F2;
  width: 10vw;
  height: 5.5vh;
  border-radius: 0.5vw;
  color: #282c34;
  font-size: 1.3em;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1vh;
  min-height: 40px;
  padding-left: .5vw;
  padding-right: .5vw;
  border: 3px solid #454546;
}
.buttonRow {
  margin-top: 3vh;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: 0;
  justify-content: flex-end;
}
.shoppingCartItemButtonFinishOrder{
  background-color: #F7A823;
  width: 10vw;
  height: 4vh;
  border-radius: 0.5vw;
  color: #F7F7F7;
  min-height: 40px;

  font-size: 1.3em;
  border-width: 0;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1vh;
  margin-left: 1vw;
}
.inviteEmailContainer{
  display: flex;
  flex-direction: column;
  width: 60vw;
}

.editorForm {
  width:60vw;
  margin-left: 2vw;
}

.addExtraColleagues{
  color: #000;

  width: 12vw;
  height: 6vh;
  font-size: 1.3em;
  cursor: pointer;
  margin-top: 1vh;
  margin-left: 1vw;
}
.addExtraColleagues:hover{
  color: #F7A823;
  width: 12vw;
  height: 6vh;
  font-size: 1.3em;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1vh;
  margin-left: 1vw;
}

.editorCollapsible{
  background-color: #f2f2f2;
  margin-bottom: 2vh;
}

.emailInput{
  width: 35vw;
  height: 4vh;
  font-size: 1.4em;
  padding-left: 1vw;
  border-radius: 0.5vw;
}
.nameInput{
  margin-left: 1vw;
  width: 25vw;
  height: 4vh;
  font-size: 1.4em;
  padding-left: 1vw;
  border-radius: 0.5vw;
}
.emailRow{
  display: flex;
  margin-bottom: 2vh;
  flex-direction: row;
}

.colleagueContainer{
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {

  .editorRow {
    display: flex;
    width: 85vw;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-bottom: 0vh;
  }

  .editor .editorInput {
    width: 80vw;
    height: 5vh;
    margin-bottom: 1vh;
    padding-left: 1vw;
    display: block;
    font-size: 1.3em;
    color: #707070;
    border-radius: 5px;
    border: 2px solid #707070;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }


  .addColleagueItemRow {
    display: flex;
    width:60vw;
    margin-left: 5vw;
    justify-content: space-between;
  }
  .addColleagueFlex {
    display: flex;
  }
  .addColleagueHeader {
    margin-left: 5vw;
    margin-right: auto;
    text-align: left;
  }
  .addColleagueHeaderTitle{
    font-family: 'NeoTech', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 1vh;
  }
  .addColleagueHeaderSubTitle {
    margin-top: 0;
    font-size: 1.2em;
  }
  .addColleagueNextPage {
    float:right;
    margin-top: 2vh;

    width: 9vw;
    border-radius: 10px;
    height:5.5vh;
    border: 0;
    font-size: 1.5em;
    font-family: 'NeoTech', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    color: white;
    background-color: #FAA634;
  }

  .noLoginNextPage {
    margin-top: 2vh;

    width: 12vw;
    border-radius: 10px;
    height:5.5vh;
    border: 0;
    font-size: 1.5em;
    font-family: 'NeoTech', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    color: white;
    background-color: #FAA634;
  }

  .shoppingCartItemButton{
    background-color: #F2F2F2;
    width: 10vw;
    height: 4vh;
    border-radius: 0.5vw;
    color: #282c34;
    font-size: 1.3em;

    cursor: pointer;
    font-weight: bold;
    margin-top: 1vh;
    padding-left: .5vw;
    padding-right: .5vw;
    border: 3px solid #454546;
  }
  .buttonRow {
    margin-top: 3vh;
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: 0;
    justify-content: flex-end;
  }
  .shoppingCartItemButtonFinishOrder{
    background-color: #F7A823;
    width: 40vw;
    margin-bottom: 1vh;
    height: 4vh;
    border-radius: 10px;
    color: #F7F7F7;
    font-size: 1.3em;
    border-width: 0;
    cursor: pointer;
    font-weight: bold;
    margin-top: 1vh;
    margin-left: 1vw;
  }
  .inviteEmailContainer{
    display: flex;
    flex-direction: column;
    width: 60vw;
  }

  .editorForm {
    width:60vw;
    margin-left: 2vw;
  }

  .addExtraColleagues{
    color: #000;

    width: 12vw;
    height: 6vh;
    font-size: 1.3em;
    cursor: pointer;
    margin-top: 1vh;
    margin-left: 1vw;
  }
  .addExtraColleagues:hover{
    color: #F7A823;
    width: 12vw;
    height: 6vh;
    font-size: 1.3em;
    cursor: pointer;
    font-weight: bold;
    margin-top: 1vh;
    margin-left: 1vw;
  }

  .editorCollapsible{
    background-color: #f2f2f2;
    margin-bottom: 2vh;
  }

  .emailInput{
    width: 35vw;
    height: 4vh;
    font-size: 1.4em;
    padding-left: 1vw;
    border-radius: 0.5vw;
  }
  .nameInput{
    margin-left: 1vw;
    width: 25vw;
    height: 4vh;
    font-size: 1.4em;
    padding-left: 1vw;
    border-radius: 0.5vw;
  }
  .emailRow{
    display: flex;
    margin-bottom: 2vh;
    flex-direction: row;
  }

  .colleagueContainer{
    display: flex;
    flex-direction: column;
  }


}
