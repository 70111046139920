
.page-title {
    font-size: 30px;
    font-family: Outfit-SemiBold, serif;

    color: #334c6e;
    display: inline;
}

.page-header-sub-title {
    font-size: 28px;
    font-family: Outfit-SemiBold, serif;
    margin: 0px;
    opacity: 0.6;
    color: #334c6e;
    display: inline;
}

.page-title-thin {
    font-size: 30px;
    font-family: Outfit-Thin, serif;
    color: #334c6e;
    display: inline;
}

.page-sub-title {
    font-size: 24px;
    font-family: Outfit-SemiBold, serif;
    color: #334c6e;
    display: inline;
}

.page-sub-title-thin {
    font-size: 24px;
    font-family: Outfit-Thin, serif;
    color: #334c6e;
    display: inline;

}

.page-sub-sub-title {
    font-family: Outfit-SemiBold, serif;
    color: #334c6e;
    display: inline;
    font-size: 19px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.page-sub-sub-sub-title {
    font-family: Outfit-Regular, serif;
    color: #428fae;
    font-size: 16px;
    margin: 0;
    font-weight: normal;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    display: inline;
}

.icon-container-content {
    font-family: Outfit-Regular, serif;
    color: #334c6e;
    font-size: 16px;
    padding-left: 8%;
    padding-right: 8%;
}

.rectangle-medium-content {
    width: 55%;
    display: block;
    margin-top: 20px;
    color: #FFF;
}

.block-faq-content {
    padding-top: 20px;
    padding-bottom: 10px;
    width: 80%;
}

.seo-content {
    padding-left: 15%;
    padding-right: 15%;
}

.text-link {
    color: #428096;
    text-decoration: underline;
    cursor: pointer;
}

.text-link:hover {
    color: #428096;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .icon-container-content {
        padding-left: 0%;
        padding-right: 0%;
    }

    .page-sub-sub-sub-title {
        font-size: 12px;
    }

    .rectangle-medium-content {
        width: 100%;
    }
}