.card {
  background: $white;
  //box-shadow: rgb(14 30 37 / 12%) 3px 5px 5px 0px;
  //-webkit-box-shadow: rgb(14 30 37 / 12%) 3px 5px 5px 0px;
  //box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  //-webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1.5em;
  //border-radius: 0.5em;
  padding: 1.5em 3em;
  height: 100%;
}
