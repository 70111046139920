/***** 3rd Party Components *****/
#backoffice {
  width: 100%;
  text-align: left;
  min-height: 100vh;
  @import "lib/normalize-css/normalize";
  @import "lib/bourbon/bourbon";
  @import "lib/neat/neat";
  @import "base/base";

  /***** Custom Layout Styles *****/
  @import "layout";

  @font-face {
    font-family: 'Rijksmuseum';
    src: local('Rijksmuseum'), url(../fonts/Rijksmuseum/Rijksmuseum-Normal.otf);
  }

  @font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans/OpenSans-Normal.ttf);
  }

  @font-face {
    font-family: 'Arial';
    src: local('Arial'), url(../fonts/Arial/Arial-Normal.ttf);
  }

  @font-face {
    font-family: 'BebasNeue';
    src: local('BebasNeue'), url(../fonts/BebasNeue/BebasNeue-Normal.otf);
  }
  @font-face {
    font-family: 'RegolaNeue';
    src: local('RegolaNeue'), url(../fonts/RegolaNeue/FTRegolaNeue-Regular.otf);
  }

  @font-face {
    font-family: 'Calibri';
    src: local('Calibri'), url(../fonts/Calibri/Calibri-Normal.ttf);
  }


  @font-face {
    font-family: 'NeoTechStd';
    src: local('NeoTechStd'), url(../fonts/NeoTechStd/NeoTechStd-Normal.otf);
  }

  @font-face {
    font-family: 'Helvetica';
    src: local('Helvetica'), url(../fonts/Helvetica/Helvetica-Normal.otf);
  }

  @font-face {
    font-family: 'MuseoSans';
    src: local('MuseoSans'), url(../fonts/MuseoSans/MuseoSans-500.otf);
  }
  @font-face {
    font-family: 'MuseoSans-900';
    src: local('MuseoSans-900'), url(../fonts/MuseoSans/MuseoSans-900.otf);
  }

  @font-face {
    font-family: "Barlow";
    src: local('Barlow-Regular'), url(../fonts/Barlow/Barlow-Regular.ttf);
  }

  @font-face {
    font-family: "Barlow";
    src: local('Barlow-Bold'), url(../fonts/Barlow/Barlow-Bold.ttf);
    font-weight: bold;
  }


  @font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Regular.ttf);
  }
  @font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Bold.ttf);
    font-weight: bold;
  }

  @font-face {
    font-family: 'Fira sans';
    src: url(../fonts/Firasans/FiraSans-Regular.ttf);
  }
  @font-face {
    font-family: 'Fira sans';
    src: url(../fonts/Firasans/FiraSans-Bold.ttf);
    font-weight: bold;
  }

  @font-face {
    font-family: 'Sofia Sans';
    src: url(../fonts/SofiaSans/SofiaSans-Regular.ttf);
  }
  @font-face {
    font-family: 'Sofia Sans';
    src: url(../fonts/SofiaSans/SofiaSans-Bold.ttf);
    font-weight: bold;
  }


  @font-face {
    font-family: 'Museo';
    src: url(../fonts/Museo/Museo300-Regular.otf);
  }
  @font-face {
    font-family: 'Museo-700';
    src: url(../fonts/Museo/Museo700-Regular.ttf);
    font-weight: bold;
  }

  @font-face {
    font-family: 'Campton';
    src: url(../fonts/Campton/Campton-Book.otf);
  }
  @font-face {
    font-family: 'Campton';
    src: url(../fonts/Campton/Campton-SemiBold.otf);
    font-weight: bold;
  }
  @font-face {
    font-family: 'Campton';
    src: url(../fonts/Campton/Campton-SemiBoldItalic.otf);
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Lexend';
    src: url(../fonts/Lexend/Lexend-Regular.ttf);
  }

  @font-face {
    font-family: 'BeyondInfinity';
    src: url(../fonts/BeyondInfinity/Beyond-Infinity-Demo.ttf);
  }

  @font-face {
    font-family: 'Aeonik';
    src: url(../fonts/Aeonik/Aeonik-Regular.otf);
  }

  @font-face {
    font-family: "Inter";
    src: url(../fonts/Inter/Inter-Regular.ttf) format("truetype");
  }

  @font-face {
    font-family: "Inter";
    src: url(../fonts/Inter/Inter-Bold.ttf) format("truetype");
    font-weight: bold;
  }

  @font-face {
    font-family: "Dassans";
    src: url(../fonts/Dassans/DasSans-Regular.ttf)
  }

  @font-face {
    font-family: "Dassans";
    src: url(../fonts/Dassans/DasSans-Bold.ttf);
    font-weight: bold;
  }

  @font-face {
    font-family: "ProximaNova";
    src: url(../fonts/ProximaNova/ProximaNova-Regular.otf);
  }
  @font-face {
    font-family: "ProximaNova";
    src: url(../fonts/ProximaNova/ProximaNova-Bold.otf);
    font-weight: bold;
  }

  @font-face {
    font-family: "Poppins";
    src: url(../fonts/Poppins/Poppins-Regular.ttf);
  }

  @font-face {
    font-family: "Poppins";
    src: url(../fonts/Poppins/Poppins-Bold.ttf);
    font-weight: bold;
  }


}
