$brand: #00CECC;
$checkboxSize: 50px;
$uncheckedBG: transparent;
$checkedBG: #ffffff;
$checkedColor: black;
$disabledBG: rgba(0, 0, 0, .35);




.React__checkbox--input {
  display: none;
}

.React__checkbox--input {
  + span {
    color: red;
    display: flex;
    width: $checkboxSize;
    height: $checkboxSize;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 5px;
    border: 1px solid black;
    border-radius: 10px;
    box-sizing: border-box;
    transition: all 150ms;
  }
  &:checked {
    + span {

      &::before {
        content: 'check';
        font-family: 'Material Icons';
        font-size: 40px;
        color: $checkedColor;
      }
    }
  }
  &:disabled {
    + span {
      background: $disabledBG;
    }
  }
}

.checkBoxFlex{
  display: flex;
}
.checkBoxLabel {
  margin-top: auto;
  margin-bottom: auto;
  line-height: 1em;
  font-size: 1.2em;
}