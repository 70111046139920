
.OuterLocale {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.localeDropdown {
  position: relative;
  justify-content: center;
  cursor: pointer;
  border: 0px;

  span {
    font-size: 1.5em;
    text-align: left;
  }
}

.localeDropdownItem {
  width: 2.2vw;
  height: 4vh;
  border-radius: 10px;
  justify-content: center;
  display: flex;

}

.localeDropdownText {

}

.activeItem {
  background-color: #c7c7c7;

  svg {
    fill: #454546 !important;
  }

  cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
  .localeDropdown {
    top: -4px;
  }
}