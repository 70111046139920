.personal-detail-right {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.personal-detail-left {
    display: flex;
    flex-direction: column;
    padding-right: 10px;

}

.editor-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
}

.icon-picker-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.editor-container {
    width: 100%;
    display: flex;

    flex-direction: row;
    padding-bottom: 40px;
    z-index: 1;
    justify-content: space-evenly;
}

.personal-detail-inner-container {
    padding-left: 40px;
}

.editor-right {

}

.editor-left {

}

.subscription-left {
    background-color: white;
    border-radius: 50%;
    height: fit-content;
}

.personal-detail-container {
    display: flex;
    flex-direction: row;

}

.profile-picture-upload-button {
    padding: 0 12px;
    display: block;
    width: fit-content;
    color: #FFF;
    border: 0;
    height: 39px;
    font-size: 17px;
    line-height: 39px;
    cursor: pointer;
    margin-top: 13px;
    border-radius: 5px;
    letter-spacing: 0.12px;
    white-space: nowrap;
    background-color: #334c6e;
}

.profile-picture-upload-button:hover {
    background-color: #334c6e;

}

.personal-detail-outer-container {
    display: flex;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);

    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    transition: all 0.7s ease;
    overflow: hidden;
    padding: 20px;
}


.editor-footer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;

}

.editor-footer-line {
    border: 0.5px solid #42809669;
    width: 40%;
    padding-left: 5%;
    padding-right: 5%;

}

.crop-tool-modal {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    background-color: #FFFFFFBB;
    padding: 15% 15% 0% 15%;
    z-index: 100;
    top: 0;
}

.editor-flow-footer {
    display: flex;
    justify-content: space-evenly;
    height: 100px;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .editor-container {
        flex-direction: column;
        align-items: center;
    }

    .personal-detail-container {
        flex-direction: column;
    }

    .personal-detail-right {
        padding-left: 0;
    }

    .editor-right {
        padding-left: 5%;
        padding-right: 5%;
    }

    .personal-detail-outer-container {
        transition: all 1s ease;

    }

    .icon-picker-container {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .personal-detail-inner-container {
        padding-left: 20px;
    }

    .personal-detail-left {
        padding-right: 0;
    }


}