.template-type-picker {
    width: 200px;
    border: 2px solid #324A6D;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: space-evenly;
    padding: 2px;
}

.template-type-item {
    color: #324A6D;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
}


.bottom-bar-back {
    color: #324A6D;
    font-weight: 700;
    width: 150px;

    cursor: pointer;

}

.bottom-bar-next {
    color: #f7a823;
    width: 150px;
    text-align: right;
    cursor: pointer;
    font-weight: 700;
}

.bottom-bar {
    display: flex;
    position: fixed;
    flex-direction: row;
    bottom: 20px;
    z-index: 999999;
    width: 85%;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    border-radius: 20px;
}

.bottom-bar-progress {
    display: flex;
    flex-direction: column;
    color: #324A6D;
    align-items: center;
}

.bizzerd-picker-header{
    display: flex;flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 5%;
}

.bizzerd-picker-logo{
    width: 200px;
    padding-top: 15px;
}

@media only screen and (max-width: 768px) {
    .bizzerd-picker-logo{
        display: none;
    }
    .bizzerd-picker-header{
        flex-direction: column;
    }
    .bottom-bar {
        bottom: 0;
        width: 100%;
        border-radius: 20px 20px 0px 0px;
    }

    .bizzerd-picker-flex {
        padding: 0px 0px !important;
    }

    .container-white {
        padding: 0;
    }
    .bottom-bar-progress{
        text-align: center;
    }
}
