.editable-input-field {
    padding: 10px 20px 10px 10px;
    border-radius: 3px;
    border: 1px solid #dfdee4;
    color: #2d4267;
    font-size: 16px;
    font-family: Outfit-Regular, serif;

}

.editable-input-field::placeholder {
    color: #dfdee4;
}

.editable-input-field:hover {
    border-radius: 5px;
}

.editable-input-field:focus-visible {
    outline: 1px solid #8bb0c0;
}

.editable-input-field:active {
    border: 1px solid #8bb0c0;
    border-radius: 3px;
}

.editable-input-field:read-only {
    background-color: #8bb0c033;
}

.editable-input-field:read-only {
    background-color: #8bb0c033;
    color: #2d426766;
}

.filled {
    border: 1px solid #b5bcc7;
    border-radius: 5px;
}

.invalid {
    border: 1px solid #da3743;
}

.invalid:focus-visible {
    outline: 1px solid #da3743;
}

.input-field-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.input-field-label {
    font-size: 14px;
    margin-left: 1px;

}

.input-field-label-error {
    font-size: 14px;
    margin-left: 1px;
    color: #da3743;
}

.input-field-label-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
}

@media only screen and (max-width:768px){
    .input-field-label-error{
        font-size: 12px;
    }

    .input-field-label{
        font-size: 12px;
    }
}