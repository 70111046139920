.subscription-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    background-color: #FFFFFF;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 40px;
    box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.2);
}

.subscription-text {
    display: flex;
    flex-direction: column;
    color: #4d4d4d;
}

.selected-bizzerd-editor-field:active {
    outline: 2px #F7A823 solid;
    outline-offset: 3px;
}


.subscription-right {
    text-align: left;
    width: 40%;
    max-width: 550px;
    min-width: 450px;
    color: #334c6e;
}

.subscription-title {
    font-weight: bold;
    color: #334c6e;
}

.subscription-inclusive {
    font-size: 20px;
}

.subscription-usp {
    display: block;
    padding-top: 2px;
    padding-bottom: 2px;
}

.subscription-graphic {
    width: 450px;
}

.shoppingcart-item {
    display: flex;
    flex-direction: row;
    width: 70%;
    background-color: white;
    align-items: center;
    height: 150px;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    margin-top: 50px;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: space-between;
}

.shoppingcart-item-left {
    display: flex;
    flex-direction: column;
}

.shoppingcart-item-right {
    display: flex;
    flex-direction: column;
}

.shoppingcart-pricing-table {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.shoppingcart-pricing-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20%;
    width: 100%;
}

.editor-right {

}

.shoppingcart-pricing-table-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    color: #428096;
}

.blue-background {
    z-index: 0;
    position: relative;
    margin-top: -300px;
    height: 400px;
    width: 100vw;
    object-fit: cover;
}

.subscription-white {
    color: #324A6D;
    position: relative;
    text-align: left;
    z-index: 100;
}


@media only screen and (max-width: 768px) {



    .subscription-graphic {
        width: 200px;
    }

    .shoppingcart-pricing-container {
        padding-right: 0;
    }

    .subscription-right {
        min-width: 350px;
        width: fit-content;
        padding-left: 3%;
        padding-right: 3%;
    }

    .subscription-left {
        margin-bottom: 40px;

    }

    .shoppingcart-item {
        flex-direction: column;
        height: fit-content;
    }

    .shoppingcart-item-left {
        width: fit-content;
        flex-direction: row;
        padding-top: 20px;
    }

    .shoppingcart-item-right {
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
        padding-bottom: 20px;
    }

    .shoppingcart-pricing-table {
        width: 95%;
    }
}