.about-header {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    padding: 0px 25%;
    align-items: center;
    gap: 20px;
}

.feature-list {
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    width: 90%;
    padding: 20px;
    background: linear-gradient(180deg, #EAF2F6 29.26%, rgba(234, 242, 246, 0.00) 100.02%);
}

.feature-list-col1 {
    width: 70%;
    display: flex;

    flex-direction: column;
    align-items: flex-start;
}

.feature-list-col2 {
    width: 15%;
    display: flex;
    padding: 10px;
    border-radius: 20px;
    flex-direction: column;
    margin-right: 20px;
    background: linear-gradient(180deg, #FFF 29.26%, rgba(234, 242, 246, 0.00) 100.02%);
}


.features-other-title {
    color: #324A6D;
    text-align: center;
    font-family: Outfit-Regular, serif;
    font-size: 22px;
    font-style: normal;
    opacity: 0.7;
    font-weight: 700;
    line-height: 22px; /* 100% */
}

.feature-list-section-header {
    color: #324A6D;
    font-family: Outfit-Regular, serif;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
}

.feature-container {
    padding: 20px 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-container {
    display: flex;
    background-color: #FFF;
    flex-direction: column;
    gap: 80px;
    overflow-x: hidden;
    align-items: center;
    margin-top: 0px;
    padding: 40px 20%;
    padding-top: 80px;
}

.product-bar {
    display: flex;
    flex-direction: column;
    padding: 20px 15%;
    font-family: Outfit-Regular, serif;
    font-size: 16px;
    font-style: normal;
    align-items: center;
    font-weight: 300;
    line-height: 28px;
    color: #324A6D;
}

.product-orange-bar {
    display: flex;
    flex-direction: column;
    padding: 20px 15%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0px 0px 50px 50px;
    background-color: #f7a823;

}

.product-orange-bar-list {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    margin-top: 20px;
    justify-content: space-evenly;
}

.product-orange-bar-item {
    background-color: #FFFFFF;
    width: 20%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 200px;
}

.product-orange-bar-img {
    height: 50px;
}

.product-orange-bar-title {
    color: #324A6D;

    font-family: Outfit-Bold, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
}

.product-item-bar-content {
    width: 75%;
}

.product-item-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
}

.product-item {
    display: flex;
    text-align: left;
    flex-direction: column;
    width: 28%;
    white-space: break-spaces;
}

.product-item-number {
    color: #F7A941;
    font-family: Outfit-Bold, serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.product-item-header {
    display: flex;
    align-items: center;
    gap: 20px;
}

.product-item-title {
    color: #324A6D;
    font-family: Outfit-Bold, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
}


.container-rectangle-small {
    display: flex;
    flex-direction: column;
    height: 190px;
    width: 300px;
    min-width: 270px;
    background-color: #FFF;
    justify-content: flex-end;
    padding-bottom: 8px;
    padding-top: 11px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    /*margin-left: 20px;*/
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    text-align: left;

}

.apple-button {
    margin-right: 15px;
    cursor: pointer;
}

.android-button {
    margin-left: 15px;
    cursor: pointer;

}

.logo-container {
    width: 70%;
    background-color: #FFF;
    border-radius: 10px;
    height: 80px;
    display: flex;
    position: relative;
    top: 40px;
    flex-direction: row;
    min-width: 800px;
    justify-content: space-evenly;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    align-items: center;
}


.container-rectangle-medium {
    width: 50%;
    padding: 30px 50px 30px 50px;
    min-width: 400px;
    text-align: left;
    flex-wrap: wrap;
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    /*height: 250px;*/
    /*border-radius: 5px;*/
}

.container-rectangle-small-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.container-rectangle-small-button {
    display: flex;
    justify-content: right;
    padding-right: 20px;
    margin-top: auto;
}

.bottom-bar-rectangle-small {
    width: 300px;
    height: 10px;
    position: relative;
    top: 15px;
    left: -5px;
    border-radius: 0px 0px 5px 5px;
}

.bizzerd-circle-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    user-select: none;
    -webkit-user-drag: none;

}

.block-faq {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    justify-content: space-evenly;
    text-align: left;
    border-top: 1px solid #334c6e4D;
}

.block-faq-header {
    display: flex;
    flex-direction: row;
    padding-right: 40px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
}

.circle-part {
    height: 200px;
    opacity: 0.7;
}

.circle-part-big {
    height: 300px;
    opacity: 0.7;

}

.price-table {
    color: #334c6e;
    font-weight: normal;
}

.detail-price {
    padding-bottom: 10px;
}

.uneven-row-table {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
}

.login-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.login-buttons-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.login-block {
    padding: 50px;
    background-color: #FFF;
    margin-top: 40px;
    margin-bottom: 20px;
    border-radius: 10px;
    width: 30%;
    color: #334c6e;
    min-width: 500px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.single-block-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    align-items: center;
    /*margin-bottom: 40px;*/
}

.single-blog-block-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    align-items: center;
    /*margin-bottom: 40px;*/
}

.plus-points-list {
    list-style-type: "+   ";

}


.min-points-list {
    list-style-type: "-   ";
}

.blog-block-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    padding-left: 15%;
    padding-right: 15%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 40px;
}

.double-block-container {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    display: flex;
    align-items: stretch;
    padding: 0px 15%;
    text-align: left;
    /*align-items: center;*/
    background-color: #EAF2F6;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.register-header {
    color: #428096;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
}

.shoppingcart-item-text-bold {
    color: #334c6e;
    font-weight: bold;
}

.container-rectangle-white {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    padding: 60px 15%;
    background-color: #FFFFFF;
    border-radius: 0px 0px 130px 130px;
}

.review-block-content {
    color: #334c6e;
    padding-bottom: 15px;
    width: 100%;
}

.upload-block {
    display: flex;
    flex-direction: column;
    padding: 50px;
    background-color: #FFF;
    margin-top: 40px;
    margin-bottom: 20px;
    border-radius: 10px;
    height: auto;
    width: 300px;
    color: #334c6e;
    min-width: 500px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.blog-block {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: #FFF;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    min-height: 200px;
    width: 100%;
    padding: 50px;
    height: auto;
    color: #334c6e;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.share-block {
    display: flex;
    flex-direction: column;
    padding: 50px;
    margin-bottom: 20px;
    border-radius: 10px;
    height: auto;
    width: 300px;
    background-image: linear-gradient(to top, #42809608, #ffffffff);
    color: #334c6e;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.share-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.share-back-button {
    position: absolute;
    left: 20px;
    font-weight: bold;
}

.share-block-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.qr-code-container {
    border: #FFFFFF solid 10px;
    margin-top: 40px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.share-button-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.share-button-small {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #FFFFFF;
    font-weight: bold;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    width: 100%;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
}

.share-button {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;

    text-align: center;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
}

.contact-page-title-bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15%;
    text-align: left;

}

.contact-page-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    padding: 0 15% 40px;
    justify-content: space-between;
}

.contact-page-header-left {
    margin-right: 20px;
    display: flex;
    width: 40%;
    flex-direction: column;
    text-align: left;
}

.calculator-outer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 80px;
    margin-left: auto;
    margin-right: auto;
    width: 1300px;
}

.calculator-options-outer-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 130px;
    align-items: start;
    justify-content: space-between;
    margin: 10px;
}

.calculator-options-title {
    color: #f7a823;
    font-size: 26px;
    font-weight: bold;
}

.calculator-options-subTitle {
    font-size: 14px;
}

.calculator-options-text-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: start;

}

.calculator-options-icon {
    width: 50px;
    transition: all 0.5s ease-in-out;
    left: 0px;
    top: 0px;
    position: relative;
}

.calculator-options-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.calculator-inner-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    transition: all 0.8s ease;
    height: 100%;
    perspective: 800px;
}

.calculator-options-icon-container {
    margin-right: 10px;
    width: 55px;
    position: relative;
    display: flex;
}

.circle {
    width: 100%;
    height: 100%;
    background-color: #f7a823;
    position: absolute;
    border-radius: 50%;
    animation: ripple-animation 3s infinite ease;
}

.circle1 {
    animation-delay: 0s;
}

.circle2 {
    animation-delay: 500ms;
}

.circle3 {
    animation-delay: 1s;
}

.calculator-phone-backside {
    transition: all 0.8s linear;
    position: absolute;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
}

.calculator-phone-normal {
    transition: all 0.8s linear;
    position: relative;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
}

.calculator-phone-special {
    transition: all 0.8s linear;
    position: absolute;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.calculator-nfc-tag {
    position: relative;
    top: 100px;
    pointer-events: none;
    z-index: 3;
    transition: left 0.8s ease-out, opacity 0.3s ease;
}

.calculator-circle-container {
    position: absolute;
    width: 300px;
    height: 300px;
    left: -535px;
    top: 40px;
    transition: opacity 0.3s ease;
}

.about-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    text-align: left;
    padding-left: 5%;
    padding-right: 5%;
}

.about-flex-row-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 50%;
}

.about-block-item {
    display: flex;
    flex-direction: column;
    width: 200px;
    align-items: flex-start;
    text-align: left !important;
    gap: 5px;
    justify-content: space-between;
}

.about-header-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

@keyframes ripple-animation {
    0% {
        transform: scale(0.1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

@media only screen and (max-width: 1300px) {
    .product-orange-bar {
        padding: 20px 5%;
    }

    .contact-page-header {
        padding: 0 10% 40px;
    }

    .contact-page-title-bar {
        padding: 0 10% 40px;

    }

    .product-bar {
        padding: 20px 5%;
    }

    .calculator-outer-container {
        padding: 0 5px;
        width: 768px;
        align-items: flex-start;
    }

}


@media only screen and (max-width: 768px) {
    .product-item-bar {
        flex-direction: column;
    }

    .product-item {
        width: 100%;
        align-items: center;
        text-align: center;
        white-space: normal;
        margin-bottom: 20px;
    }

    .product-orange-bar {
        padding: 20px 5%;
        padding-bottom: 30px;
    }


    .product-orange-bar-list {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
    }

    .product-orange-bar-item {
        width: 45%;
        min-width: 40%;
        max-width: 50%;

    }

    .about-flex-row-content {
        max-width: 100%;
    }

    .about-header-buttons {
        flex-direction: column;
        align-items: center;
    }

    .about-block-item {
        text-align: center !important;
        align-items: center;

    }

    .about-flex-row {
        flex-direction: column;
        align-items: center;
        text-align: center !important;
    }

    .about-header {
        padding: 0px 5%;
    }

    /*.calculator-phone-normal {*/
    /*    max-width: 300px;*/
    /*}*/
    /*.calculator-phone-special {*/
    /*    max-width: 300px;*/
    /*}*/
    /*.calculator-phone-backside {*/
    /*    max-width: 300px;*/
    /*}*/
    .calculator-circle-container {
        left: auto;
        top: -710px;
    }

    .calculator-nfc-tag {
        transition: top 0.8s ease-out, opacity 0.3s ease;
    }

    .calculator-inner-container {
        align-items: center;
        width: 100%;
    }

    .calculator-outer-container {
        flex-direction: column;
        align-items: center;
        padding: 0;
        max-width: 375px;
    }


    .contact-page-title-bar {
        flex-direction: column;
        padding: 0 5% 20px;
        justify-content: flex-start;
        align-items: flex-start;

    }

    .contact-page-header {
        flex-direction: column;
        padding: 0 5% 40px;

    }

    .contact-page-header-left {
        width: 100%;
    }

    .logo-container {
        width: 90%;
        min-width: 100px;
    }

    .container-rectangle-white {
        border-radius: 0px 0px 50px 50px;
        padding-top: 10px;
    }

    .container-rectangle-small-button {
        display: none;
    }

    .container-rectangle-small {
        justify-content: space-between;
        height: 95px;
        min-width: 320px;
        width: 90%;
        text-decoration: none;
    }

    .bottom-bar-rectangle-small {
        width: 103%;
        height: 7px;
    }


    .blog-block {
        padding: 30px;
    }

    .blog-block-container {
        padding-left: 5%;
        padding-right: 5%;
    }

    .apple-button {
        margin-right: 0px;
    }

    .android-button {
        margin-left: 0px;
    }

    .circle-part {
        height: 150px;
    }

    .circle-part-big {
        height: 250px;
    }

    .container-rectangle-medium {
        width: 100%;
        min-width: 300px;

    }

    .login-block {
        min-width: 340px;
        padding: 40px;
    }

    .login-buttons {
        flex-direction: column;
    }

    .login-buttons-group {
        padding-bottom: 10px;
    }
}


@media only screen and (max-width: 568px) {
    .circle-part {
        height: 100px;
    }

    .circle-part-big {
        height: 150px;
    }
}


