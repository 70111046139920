// Media queries
$media--landscape: (
        media: 550px,
);

$media--tablet: (
        media: 768px,
);

$media--desktop: (
        media: 768px,
);

$media--fullscreen: (
        media: 1200px,
);

// Typography
$base-font-family: $font-stack-system;
$heading-font-family: $base-font-family;

// Font sizes
$heading1-font-size: 2.236em;
$heading2-font-size: 1.618em;
$heading3-font-size: 1.309em;
$article-font-size: 0.9em;


// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0.3em;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2.5;
$medium-spacing: $base-spacing / 1.5;
$base-z-index: 0;
$main-image-width: 100%;
$main-image-height: 348px;

// Colors
$action-color: #F7A823;
$white: #fff;
$light-gray: #F7F7F7;
$lightmedium-gray: #CACACA;
$medium-gray: #999;
$darkmedium-gray: #585858;
$dark-gray: #444;
$darkest-gray: #2B2B2B;
$danger-red: #FB6D77;


// Shadows
$shadow-normal: 0 2px 4px 0 rgba(14, 30, 37, .12);
$shadow-hover: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
$shadow-active: 0 4px 6px rgba(50, 50, 93, 0.04), 0 1px 3px rgba(0, 0, 0, 0.04);

// Font Colors
$base-header-color: $darkest-gray;
$base-font-color: #334c6e;

// Border
$base-border-color: $medium-gray;
$base-border: 1px solid lighten($base-border-color, 15%);

// Background Colors
$base-background-color: $white;
$secondary-background-color: tint($base-border-color, 75%);

// Focus
$focus-outline-color: darken($action-color, 10);
$focus-outline-width: 3px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: -1px;

// Animations
$base-duration--fast: 180ms;
$base-duration: 280ms;
$base-duration--slow: 380ms;
$base-timing: cubic-bezier(.4, 0, .2, 1);
$base-timing--aggresive: cubic-bezier(.84, 0, .2, 1);
