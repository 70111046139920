.popup-chat {
    width: 350px;
    height: 70vh;
    position: absolute;
    background-color: white;
    border-radius: 10px;
    transition: all 0.5s ease;
    position: absolute;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
    left: -230px;
    overflow: hidden;
    top: calc(-70vh - 20px);
}

.popup-chat-header {
    background-color: #f7a823;
    width: 100%;
    height: 40px;
}

.popup-chat-header-title {
    line-height: 40px;
    font-size: 22px;
    color: #FFFFFF;
}

.popup-chat-content {
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    text-align: left;

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }

    /* Handle on hover */

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.popup-chat-message {
    display: flex;
    flex-direction: row;

    color: #334c6e;
}

.message-bizzerd {
    width: 80%;
    padding: 10px;
    margin: 5px;
    border-radius: 10px;

    background-color: #FDF9F1;
}

.message-anonymous {
    background-color: #EAF2F6;
    width: 80%;
    padding: 10px;
    border-radius: 10px;
    margin: 5px;

}

.popup-chat-messages {
    min-height:  45vh;
    max-height:  45vh;
    margin-top: 10px;

    overflow-y: scroll;
    scroll-behavior: smooth;
}

.popup-chat-send {
    margin-left: 20px;
    margin-reft: 20px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 500px) {
    .popup-chat {
        height: 70vh;
        top: -70vh;
        width: 80vw;
        left: -60vw;
        max-width: 350px;

    }

    .popup-chat-messages {
        min-height: 45vh;
        max-height: 45vh;
    }

}