.footer-main {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    width: 100%;
    padding: 0px 15%;
}

.footer-bottom {
    display: flex;
    width: 100%;
    padding-left: 15%;
    flex-direction: row;
    padding-right: 15%;
    justify-content: space-between;
    align-items: center;
}

.footer-item-column {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    padding-right: 13%;
    text-align: left;
}

.footer-item-title {
    margin-bottom: 20px;
    font-size: 18px;
    text-decoration: none;
    color: #334c6e;
    font-weight: bold;
}

.footer-item-standard {
    font-size: 16px;
    margin-bottom: 10px;
    text-decoration: none;
    white-space: nowrap;
    color: #334c6e;
}

.footer-icon {
    padding-right: 30px;
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .footer-icon {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .footer-main {
        flex-wrap: wrap;
        padding-left: 7%;
        padding-right: 7%;
        justify-content: flex-start;
    }

    .footer-item-column {
        padding-left: 0%;
        padding-top: 40px;
        width: 150px;
    }


    .footer-bottom {
        flex-direction: column;
        padding-left: 7%;
        padding-right: 7%;
        align-items: center;
        height: 150px;
        justify-content: space-between;
    }
}