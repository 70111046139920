.sales-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: all 0.5s ease;
  z-index: 1000;
}

.invisible {
  right: -200px;
  transition: all 0.5s ease;
}

.sales-popup img {
  max-width: 130px;
  transition: all 0.1s ease;
}


.sales-popup img:hover {
  max-width: 160px;

}


.shopContainer {
  background-color: #FFFFFF;

  .button--primary {
    cursor: pointer;
    width: fit-content;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 5.5vh;
    border: 0;
    min-height: 40px;
    font-size: 1.5em;
    font-family: 'NeoTech', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    color: white;
    background-color: #FAA634;
  }

  * {
    box-sizing: border-box
  }
}

@media only screen and (max-width: 768px) {
  .sales-popup img {
    max-width: 80px;
    transition: all 0.1s ease;
  }

  .sales-popup img:hover {
    max-width: 80px;

  }
}