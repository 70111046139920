@font-face {
    font-family: 'Rijksmuseum';
    src: local('Rijksmuseum'), url(../fonts/Rijksmuseum/Rijksmuseum-Normal.otf);
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans/OpenSans-Normal.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Medium.ttf);
}

@font-face {
    font-family: 'OpenSans-Light';
    src: local('OpenSans-Light'), url(../fonts/OpenSans/OpenSans-Light.ttf);
}

@font-face {
    font-family: "Barlow";
    src: local('Barlow-Regular'), url(../fonts/Barlow/Barlow-Regular.ttf);
}

@font-face {
    font-family: "Barlow";
    src: local('Barlow-Bold'), url(../fonts/Barlow/Barlow-Bold.ttf);
    font-weight: bold;
}


@font-face {
    font-family: 'Arial';
    src: local('Arial'), url(../fonts/Arial/Arial-Normal.ttf);
}

@font-face {
    font-family: 'BebasNeue';
    src: local('BebasNeue'), url(../fonts/BebasNeue/BebasNeue-Normal.otf);
}

@font-face {
    font-family: 'Calibri';
    src: local('Calibri'), url(../fonts/Calibri/Calibri-Normal.ttf);
}


@font-face {
    font-family: 'NeoTechStd';
    src: local('NeoTechStd'), url(../fonts/NeoTechStd/NeoTechStd-Normal.otf);
}

@font-face {
    font-family: 'Helvetica';
    src: local('Helvetica'), url(../fonts/Helvetica/Helvetica-Normal.otf);
}

@font-face {
    font-family: 'MuseoSans';
    src: local('MuseoSans'), url(../fonts/MuseoSans/MuseoSans-500.otf);
}

@font-face {
    font-family: 'MuseoSans-900';
    src: local('MuseoSans-900'), url(../fonts/MuseoSans/MuseoSans-900.otf);
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: 'Fira sans';
    src: url(../fonts/Firasans/FiraSans-Regular.ttf);
}

@font-face {
    font-family: 'Fira sans';
    src: url(../fonts/Firasans/FiraSans-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: 'Museo';
    src: url(../fonts/Museo/Museo300-Regular.otf);
}

@font-face {
    font-family: 'Museo-700';
    src: url(../fonts/Museo/Museo700-Regular.ttf);
    font-weight: bold;
}

@font-face {
    font-family: 'Asap';
    src: url(../fonts/Asap/Asap-Regular.ttf);
}

@font-face {
    font-family: 'Asap';
    src: url(../fonts/Asap/Asap-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: 'Asap';
    src: url(../fonts/Asap/Asap-Italic.ttf);
    font-style: italic;
}

@font-face {
    font-family: 'Campton';
    src: url(../fonts/Campton/Campton-Book.otf);
}

@font-face {
    font-family: 'Campton';
    src: url(../fonts/Campton/Campton-SemiBold.otf);
    font-weight: bold;
}

@font-face {
    font-family: 'Campton';
    src: url(../fonts/Campton/Campton-SemiBoldItalic.otf);
    font-weight: bold;
    font-style: italic;
}


@font-face {
    font-family: 'EBGaramond';
    src: url(../fonts/EBGaramond/EBGaramond-Regular.ttf);
}

@font-face {
    font-family: 'EBGaramond';
    src: url(../fonts/EBGaramond/EBGaramond-BoldItalic.ttf);
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'EBGaramond';
    src: url(../fonts/EBGaramond/EBGaramond-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: 'EBGaramond';
    src: url(../fonts/EBGaramond/EBGaramond-Italic.ttf);
    font-style: italic;
}

@font-face {
    font-family: 'PlayfairDisplay';
    src: url(../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf);
}

@font-face {
    font-family: 'PlayfairDisplay';
    src: url(../fonts/PlayfairDisplay/PlayfairDisplay-Italic.ttf);
    font-style: italic;
}

@font-face {
    font-family: 'PlayfairDisplay';
    src: url(../fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.ttf);
    font-style: italic;
    font-weight: bold;
}

@font-face {
    font-family: 'PlayfairDisplay';
    src: url(../fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: 'PlayfairDisplay';
    src: url(../fonts/PlayfairDisplay/PlayfairDisplay-Medium.ttf);
}

@font-face {
    font-family: 'PlayfairDisplay';
    src: url(../fonts/PlayfairDisplay/PlayfairDisplay-Black.ttf);
}


@font-face {
    font-family: "Outfit-Regular";
    src: url(./fonts/Outfit/Outfit-Regular.ttf);

}

@font-face {
    font-family: "Outfit-SemiBold";
    src: url(./fonts/Outfit/Outfit-SemiBold.ttf);
}

@font-face {
    font-family: "Outfit-Bold";
    src: url(./fonts/Outfit/Outfit-Bold.ttf);

}

@font-face {
    font-family: "Outfit-Thin";
    src: url(./fonts/Outfit/Outfit-Thin.ttf);
}
@font-face {
    font-family: "Outfit-Light";
    src: url(./fonts/Outfit/Outfit-Light.ttf);
}

@font-face {
    font-family: "ZapfDingBats";
    src: url(./fonts/ZapfDingBats/ZapfDingbatsITC.ttf);
}

@font-face {
    font-family: 'Lexend';
    src: url(./fonts/Lexend/Lexend-Regular.ttf);
}

@font-face {
    font-family: 'BeyondInfinity';
    src: url(./fonts/BeyondInfinity/Beyond-Infinity-Demo.ttf);
}

@font-face {
    font-family: 'Aeonik';
    src: url(./fonts/Aeonik/Aeonik-Regular.otf);
}

@font-face {
    font-family: "Inter";
    src: url(./fonts/Inter/Inter-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Inter";
    src: url(./fonts/Inter/Inter-Bold.ttf) format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Dassans";
    src: url(./fonts/Dassans/DasSans-Regular.ttf)
}

@font-face {
    font-family: 'Sofia Sans';
    src: url(./fonts/SofiaSans/SofiaSans-Regular.ttf);
}
@font-face {
    font-family: 'Sofia Sans';
    src: url(./fonts/SofiaSans/SofiaSans-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: 'RegolaNeue';
    src: local('RegolaNeue'), url(./fonts/RegolaNeue/FTRegolaNeue-Regular.otf);
}

@font-face {
    font-family: "Dassans";
    src: url(./fonts/Dassans/DasSans-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: "ProximaNova";
    src: url(./fonts/ProximaNova/ProximaNova-Regular.otf);
}
@font-face {
    font-family: "ProximaNova";
    src: url(./fonts/ProximaNova/ProximaNova-Bold.otf);
    font-weight: bold;
}

@font-face {
    font-family: "Poppins";
    src: url(./fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
    font-family: "Poppins";
    src: url(./fonts/Poppins/Poppins-Bold.ttf);
    font-weight: bold;
}
    



body {
    margin: 0;
    font-family: Outfit-Regular, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #454546;
}

code {
    font-family: Outfit-Regular, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

b {
    font-family: Outfit-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}