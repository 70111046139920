.dot {
    height: 10px;
    width: 10px;
    background-color: #2d4267;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 5px;
    margin-top: 5px;
}


::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.bizzerd-picker-flex-container {
    width: 100%;
    text-align: center;
    height: 800px;
    overflow-y: hidden;
    display: flex;
    max-height: 100vh;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}

.bizzerd-picker-flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-wrap: wrap;
    height: 90%;
    padding: 0px 15%;
    margin-top: 10px;

}

.checklist-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 300px;
    height: fit-content;
    align-items: center;
    left: 154px;
    top: 200px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    position: fixed;
    background-color: #2d4267;
}

.checklist-item-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 5px;
}

.checklist-item-text {
    color: #FFFFFF;
}

.checklist-item-text-completed {
    color: #DDDDDD;
    text-decoration: line-through;

}

.designtool-profile-picture-upload-button {
    padding: 0 12px;
    display: block;
    width: fit-content;
    height: 39px;
    font-size: 17px;
    line-height: 39px;
    cursor: pointer;
    border-radius: 5px;
    letter-spacing: 0.12px;
    white-space: nowrap;
    margin-left: 15px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 0.6px solid #d3d9e1;
    color: #334c6e;
}

.designtool-profile-picture-upload-button:hover {
    background-color: #f6f6f6;
    border: 0.6px solid #91bed0;
}

.bizzerd-picker-card-container {
    cursor: pointer;
    z-index: 4;
    transition: all 0.1s ease;
}

.bizzerd-picker-card-container:hover + .bizzerd-picker-mask {
    display: flex;
    opacity: 1.0;
    z-index: 99999;
}

.bizzerd-picker-mask:hover {
    display: flex;
    opacity: 1.0;
    z-index: 99999;
}

.bizzerd-picker-card-outer-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: 10px;

}


.bizzerd-picker-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    height: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    opacity: 0.0;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    background-color: #2B2B2BAA;
    z-index: 998;


}


/*.bizzerd-picker-card-container:hover ~ .bizzerd-picker-mask {*/
/*    display: block;*/
/*}*/


.bizzerd-picker-card-container:hover {
    transform: scale(1.5);
    z-index: 999;
}

.floating-item:hover {
    fill: #F7A823;
    color: #F7A823;
}

.floating-add-item:hover {
    fill: #F7A823;
    color: #F7A823;
}

.floating-add-item-text {
    margin-left: 10px;
}

.floating-add-menu-item {
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
    align-items: center;
    justify-content: flex-end;
    color: #324A6D;
    fill: #324A6D;
}

.floating-add-menu-item:hover {
    fill: #F7A823 !important;
    color: #F7A823 !important;

}

.floating-add-menu {
    width: 200px;
    height: 200px;
    top: 50px;
    left: 163px;
    position: absolute;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    float: right;
    transition: all .05s ease;
}

.floating-sub-menu {
    width: 200px;
    height: fit-content;
    top: 50px;
    left: 327px;
    position: absolute;
    padding: 15px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transition: all .05s ease;
}

.floating-item {
    width: 20px;
    height: 20px;
    fill: #324A6D;
    animation: all ease 0.4s;
    cursor: pointer;
}

.floating-add-item {
    width: 20px;
    height: 20px;
    cursor: pointer;
}


.floating-item-inactive {
    width: 20px;
    height: 20px;
    fill: #dbdbdb;
}

.floating-border {
    border-left: 1px solid #324A6D;
    margin-left: 10px;
    margin-right: 10px;
    align-self: stretch;
}

.floating-border-horizontal {
    border-bottom: 1px solid #324A6D;
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: stretch;
}

.floating-menu-container {
    display: flex;
    flex-direction: row;
    height: 40px;
    padding: 5px 10px;
    position: absolute;
    align-items: center;
    left: 250px;
    top: 40px;
    width: fit-content;
    z-index: 9999;
    background-color: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.floating-item-container {

}

.editorStage {
    position: relative;
    left: 400px;
    width: 100vw;
}

.stageContainer {
    display: flex;
    flex-direction: row;
}

.advancedEditorContainer {
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.foldedSideBar {
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 800px;
    z-index: 12;
}

.leftTopBar {
    display: flex;
    flex-direction: row;
    margin-left: 150px;
    align-items: center;
}

.tab-select {
    cursor: pointer;
    padding: 5px;
}

.tab-select:hover {
    background-color: #ececec;
}

.rightTopBar {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 100px;
}

.foldedSideBarNotOpen {
    display: flex;
    flex-direction: column;
    box-shadow: 5px 0 5px -5px #333;
    width: 100px;
    height: 800px;
    z-index: 12;
}

.topBar {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    z-index: 10;
    height: 70px;
    background-color: #ffffff;
}

.foldedSideBarItem {
    height: 10vh;
    width: 100px;
    display: block;
    text-align: center;
}


.unfoldedSideBarImages {
    width: 370px;
    height: 800px;
    display: flex;
    overflow: scroll;
    left: 100px;
    position: absolute;
    border: solid #000;
    border-width: 0 1px 0 1px;
    flex-wrap: wrap;
    padding: 0 4px;
    background-color: #FFF;
}

.unfoldedSideBarUploads {
    width: 370px;
    height: 800px;
    left: 100px;
    position: absolute;
    /*display: flex;*/
    /*flex-direction: column;*/
    overflow: scroll;
    border: solid #000;
    border-width: 0 1px 0 1px;
    flex-wrap: wrap;
    padding: 0 4px;
    background-color: #FFF;
}

.unfoldedSideBar {
    width: 370px;
    left: 100px;
    position: absolute;
    height: 800px;
    display: block;
    overflow: scroll;
    border: solid #000;
    border-width: 0 1px 0 1px;
    padding: 5px;
    background-color: #FFF;
}

.text-select {
    font-size: 16px;
    padding: 2px;
    margin-left: 25px;
    cursor: pointer;
}

.text-select-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.text-select:hover {
    background-color: #ececec;
}

.arrow_up {
    width: 0;
    height: 0;
    cursor: pointer;

    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #428fae;
}

.arrow_down {
    width: 0;
    cursor: pointer;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #428fae;
}


.right-bar-seperator {
    border-top: 1px solid #FFFFFF;
    margin: 25px 5px;
}

.horizontalPosition {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 10px;

    width: 100px;
}

.horizontalPositionSelect {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.horizontalPositionRow {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    width: 280px;
}

.positionInput {
    width: 70px !important;
    height: 30px;
    margin-left: 10px !important;
    font-weight: bold;
    color: #2d4267;
    margin-bottom: 5px !important;
}

.positionInputLabel {
    font-weight: bold;
}

.modal-editor-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00000033;
}

.modal-editor-container {
    width: 500px;
    height: 400px;
    border-radius: 10px;
    display: flex;
    z-index: 101;
    align-items: center;
    padding: 20px 50px;
    justify-content: space-between;
    flex-direction: column;
    background-color: #FFF;
}

.unfoldedRightSideBar {
    width: 300px;
    height: 1000px;
    color: #324A6D;
    display: block;
    position: fixed;
    right: 0;
    transition: 0.1s all ease;
    padding: 5px;
    background-color: #FFFFFF;
}

.unfoldedRightSideBar::-webkit-scrollbar {
    display: none;
}

.right-bar-group {
    background-color: #FFFFFF;
    border: 1px solid #DDE8EE;
    padding-bottom: 20px;
    padding-top: 10px;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.unfoldedLeftSideBar {
    width: 300px;
    height: 1000px;
    display: block;

    padding: 5px;
    background-color: #FFF;
}

.objectFitSelect {
    margin-left: 10px !important;
    height: 30px !important;
}

.column {
    flex: 50%;
    padding: 0 2px;
}

.userLink {
    color: #f7a823 !important;
    text-decoration: none !important;
}

.img__wrap {
    position: relative;
    /*height: 200px;*/
    /*width: 257px;*/

}

* {
    box-sizing: border-box
}

.uploads {

}

.iconSetHolder {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 180px;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-left: 25px;
}

.iconSetHolder:hover {
    background-color: #8f8f8f;
}

.img__wrap:hover {
    background-color: #7c7c7c;

}


.img__description {
    position: absolute;
    bottom: 0px;
    left: 0px;
    font-size: 10px;
    padding: 3px;
    padding-top: 10px;
    text-align: center;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
    width: 100%;
    opacity: 0;
    color: white;
}

.img__wrap:hover .img__description {
    visibility: visible;
    opacity: 1;
}

@media only screen and (max-width: 770px) {

    .bizzerd-picker-mask {
        flex-direction: column;
    }

    .bizzerd-picker-flex-container {
        width: 100%;
        padding: 10px;
        height: 88vh;
    }

    .floating-menu-container {
        top: calc(100% - 80px);
        position: fixed;
        width: 100%;
        justify-content: space-evenly;
        left: 0;
        height: 80px;
        border-radius: 10px 10px 0px 0px;
    }

    .checklist-container {
        left: 20px;
        top: 20px;
    }

    .checklist-container-closed {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30px;
        top: 20px;
        left: 20px;
        height: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        z-index: 100;
        position: fixed;
        background-color: #2d4267;

    }

    .floating-add-menu {
        top: auto;
        bottom: 85px;
        right: 10px;
        left: auto;
    }

    .floating-sub-menu {
        top: auto;
        bottom: 85px;
        left: auto;
        right: 175px;
    }
}
