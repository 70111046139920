.container-orange {
    width: 100%;
    height: 100%;
    padding: 20px 15%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    background-size: cover;
    background-color: #F7A823;
}

.container-white {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.medium-rectangle-container {

}

.container-cards {
    /*background-color: #42809610;*/
    width: 100%;
    height: 100%;
    padding: 60px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

}

.container-cards-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}

.container-inner-cards {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    padding: 0px 10%;
    align-items: center;
    justify-content: space-evenly;
}

.variation-ball {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

    border: 2px #FFF solid;
}

.variation-ball:hover {
    border: 1px #000 solid;
}

.selected {
    /*border: 1px #000 solid !important;*/
}

svg {
    display: block;
}

.variation-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.container-round-bizzerd {
    display: flex;
    border: 5px #FFF solid;
    border-radius: 10px;
    justify-content: center;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 2;

    align-items: center;
}

.container-icon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 400px;
    height: 125px;
    text-align: left;

    align-items: center;

}

.icon-outer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 80px;
}

.container-icons {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px 15% 60px;
}

.icon-usp {
    width: 60px;
    height: 80px;
    min-width: 60px;
    max-width: 60px;
    margin-right: 20px;
    display: block;
}

.container-gradient {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    background-image: linear-gradient(to top, #42809620, #ffffff40);
    align-items: center;

}

.small-rectangle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 5px 15%;
    flex-wrap: wrap;
}

.container-gradient-backwards {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 40px;
    background-image: linear-gradient(to bottom, #42809620, #ffffff40);
    align-items: center;

}


.container-gray-gradient {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: linear-gradient(12deg, #ffffff10 57%, #42809620 76%);
    background-color: #FFF;
    align-items: center;

}

.title-button-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 40px;

    width: 100%;
}

.container-faq {
    margin-top: 40px;
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.container-blue {
    padding-top: 80px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: linear-gradient(to top, #ffffff40, #42809620);
}

.business-cards-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}


.title-thin-container {

}

.container-cards-picker {
    width: 100%;
    align-self: flex-end;
    padding-top: 0;
    /*background-image: linear-gradient(to top, #42809640, #ffffff40);*/
    background-color: #42809620;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detail-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 60px;
}

.detail-right {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 50%;
    padding-right: 15%;
    justify-content: space-evenly;
}

.detail-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    min-width: 500px;
}

.content-wrapper {
    max-width: 1920px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 2500px) {
    .container-orange {
        padding: 20px 192px;
    }
}

@media only screen and (max-width: 1300px) {
    .small-rectangle-container {
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: 1100px) {
    .detail-right {
        padding-left: 3%;
        padding-right: 7%;
    }

    .detail-left {
        width: 100%;
        min-width: auto;
    }
}

@media only screen and (max-width: 768px) {

    .container-orange {
        padding: 15px 5%;
    }

    .container-cards {
        padding-bottom: 0px;
    }

    .icon-outer-container {
        flex-direction: column;
        margin-top: 20px;
    }

    .container-gray-gradient {
        border-radius: 0 0 50px 50px;
    }

    .small-rectangle-container {
        flex-direction: column;
        align-items: center;
        padding: 5px 0%;
    }

    .medium-rectangle-container {
        padding: 0px;
        flex-direction: column;
    }


    .title-button-row-container {
        padding-left: 5%;
        padding-right: 5%;
        justify-content: center;
        padding-bottom: 0;
    }

    .title-thin-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
    }

    .container-icon {
        width: 350px;
        margin-top: 10px;
        margin-bottom: 10px;
        height: fit-content;
    }

    .detail-container {
        flex-direction: column;
    }

    .detail-right {
        width: 100%;
        padding-top: 40px;
    }

    .container-cards-picker {
        width: 100%;
    }

    .business-cards-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .icon-usp {
        width: 45px;
        height: 45px;
    }

    .container-faq {
        padding-left: 5%;
        padding-right: 5%;
    }

    .container-round-bizzerd {
        border: 4px #FFF solid;
        border-radius: 2px;

    }

    .container-icons {
        padding-left: 7%;
        padding-right: 7%;
        padding-bottom: 40px;
    }

    .container-white {
        padding: 40px 7%;
    }
}


