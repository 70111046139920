.toolbarContainer {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1000;
  width: 100%;
  background-color: white;
  height: 110px;
  justify-content: start;
  transition: 0.4s;
  //margin-bottom: 100px;
  box-shadow: rgb(14 30 37 / 12%) 3px 5px 5px 0px;
  //padding-top: 1vh;
  //border-bottom: #F2F2F2 solid 2px;
}

.is--scrolled {
  transition: 0.4s;
  animation: slide-in--down 420ms cubic-bezier(.165, .84, .44, 1);
  width: 90%;
  position: fixed;
  height: 90px;
  box-shadow: rgb(14 30 37 / 5%) 0px 5px 5px 5px;
  //top: 5%;
  border-radius: 10px 10px 10px 10px;
  margin-top: 0.5%;
  margin-left: 5%;
  margin-right: 5%;
  //left: 5%;
}

.is--scrolled-logo {
  transition: 0.4s;
  margin-left: 0vw !important;
}

.middleSection {
  margin-left: 70px;
  font-size: 16px !important;
  white-space: nowrap;
}

.logoSection {
  transition: 0.4s;
  margin-left: 10%;
  max-width: 15vw;
  min-width: 7vw;
  text-align: left;
}

.logo {
  max-width: 80%;

  &:hover {
    cursor: pointer;
  }
}

.searchbarSection {
  margin-right: auto;
  margin-left: 5%;
}

.rightSection {
  margin-left: auto;
  margin-right: 5vw;
  display: flex;
  align-items: center;
  padding-top: 0vh;

  .toolbarItemFlag {
    position: relative;
    padding-top: 2vh;
    top: 8px;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    text-align: center;
    margin-right: 5px;
    margin-top: -1px;
    align-items: center;
    font-size: 1em;
    height: 5vh;
  }

  .toolbarItem {
    padding: 0vh 1vw 0vh 1vw;
    cursor: pointer;

    vertical-align: middle;
    font-size: 18px;


    .toolbarIcon {
      position: relative;
      //width: 2em;
      //height: 2em;
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 1em;
    }

    .icon {
      width: 1.5em;
      height: 1.5em;
    }

  }

  .toolbarItemBack {
    padding: 1vh 0vw 1vh 1vw;
    cursor: pointer;
    width: 7vw;
    vertical-align: middle;
    font-size: 1.0em;

    .toolbarIconBack {
      position: relative;
      top: 2px;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-right: 5px;
      font-size: 1em;
      height: 4vh;
    }

    .toolbarIconText {
      font-size: 1.2em;
    }
  }

}

.flagImage {
  //position: absolute;
  //top: 1.5vh;
  //left: .55vw;
  width: 2em;
  //height: auto;
  //border: 1px solid red;
}

@media only screen and (max-height: 768px) {
  .toolbarContainer {
    height: 80px;
  }
}

@media only screen and (max-width: 1200px) {
  .is--scrolled {
    width: 90%;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 1000px) {
  .logoSection {
    margin-left: 3vw;
  }
  .is--scrolled {
    width: 100%;
    margin-left: 0%;
  }
  .middleSection {
    margin-left: 10px;
    font-size: 16px !important;
  }

}

@media only screen and (min-width: 1000px) and (max-height: 800px) {
  .rightSection {
    .toolbarItem {
      font-size: 16px;
    }
  }

}

@media only screen and (max-width: 768px) {
  .logoSection {
    margin-left: 3vw;
    max-width: 25vw;
  }
  .logo {
    width: 5em;
    position: relative;
    left: 60px;
    //padding-top: 2vh;
  }
  .toolbarContainer {
    display: flex;
    position: fixed;
    flex-direction: row;
    height: 70px;
    align-items: center;
    background-color: white;
    //padding-top: 1vh;
    //padding-bottom: 1vh;
    //left: 50px;
    //padding-right:5/0px;

  }
  .rightSection {
    .toolbarItem {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;

      .toolbarIcon {
        top: -4px;
        position: relative;
        height: fit-content;
        width: fit-content;

      }

      .toolbarIconFlag {
        top: -4px;
        position: relative;
        height: fit-content;
        width: fit-content;

      }

      .toolbarIconLogin {
        top: 0px;
        height: fit-content;
        width: fit-content;

      }
    }
  }
  .flagImage {

    width: 30px;
  }
}
