.swatch {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  margin-left: 10px;
}

.popover {
  position: absolute;
}

.colorPickerContainer {
  display: flex;
  flex-direction: row;
}

.sketch-picker {
  z-index: 101;
}

.cover {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
