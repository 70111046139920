.header-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-top: 50px;
    margin-bottom: 50px;
    flex-wrap: wrap;
}

.navigation-item-selected {
    color: #F7A823 !important;
    font-weight: bold;
}

.product-header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 80px 15%;
    width: 100%;
    background: linear-gradient(180deg, #EAF2F6 4.49%, #FFF 100%);
}


.dashboard-navigation-header {
    display: flex;
    padding-left: 30%;
    padding-right: 30%;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 50px;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0px 7px 0 rgba(0, 0, 0, 0.2);
}

.dashboard-navigation-header a {
    text-decoration: none;
    color: #334c6e;
}

.dashboard-navigation-header a:hover {
    text-decoration: none;
    color: #f6a723;
}

.navigation-item {
    font-weight: bold;
    text-decoration: none;
    color: #334c6e;
}

.navigation-item:hover {
    color: #F7A823;
}

.navigation-header-container {
    display: flex;
    position: fixed;
    transition: all 0.4s ease;
    flex-direction: row;
    align-items: center;
    z-index: 9999999;
    justify-content: space-between;
    padding: 15px 5%;
    width: 100%;
    background-color: #fff;
}

.scrolled {
    position: fixed;
    top: 30px;
    background-color: #FFF;
    width: 90%;
    z-index: 500;
    left: 5%;
    padding-left: 10%;
    padding-right: 10%;
    border-radius: 15px;
    box-shadow: 0 0px 7px 0 rgba(0, 0, 0, 0.2);

}

.navigation-header-bizzerd-logo {
    width: 150px;
    cursor: pointer;
}

.navigation-header-button {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navigation-header-pages {
    display: flex;
    flex-direction: row;
    width: 60%;
    min-width: 450px;
    gap: 30px;
    align-items: center;
}

.navigation-header-pages a {
    color: #324A6D;

    font-family: Outfit-Regular, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
}

.home-header-usp {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.enterprise-header-usp {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /*font-family: Outfit-Light, serif;*/

}

.enterprise-header-usp-item {
    font-family: Outfit-Light, serif;

}


.home-header-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    padding-bottom: 80px;
}

.enterprise-header-left {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    max-width: 672px;
}

.product-block-left {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    width: 100%;
    padding: 20px;
    color: #324A6D;
    max-width: 672px;
    font-family: Outfit-Regular, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
}

.product-block-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 672px;
}

.product-header-left {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    width: 60%;
    padding: 20px;
    color: #324A6D;
    max-width: 672px;
    font-family: Outfit-Regular, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
}

.product-header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    max-width: 672px;
}

.enterprise-header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 672px;
}

.enterprise-header-container {
    display: flex;
    flex-direction: row;
    padding: 40px 15%;
    /*padding-bottom: 0;*/
    justify-content: center;
    width: 100%;

}

.enterprise-header-usp-item-link {
    text-decoration: underline;
    color: #454546;
    text-align: left;

}

.enterprise-header-usp-item-link:hover {
    color: #F7A823;
}


.home-header-container {
    display: flex;
    flex-direction: row;
    padding: 40px 15%;
    padding-bottom: 0px;
    width: 100%;
}

.home-header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    max-width: 960px;

}


.check-mark {
    font-family: ZapfDingbats, serif;
    color: #809d3f;
    margin-right: 10px;
    display: inline-block;
    width: 20px;
    min-width: 20px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-size: contain;
    height: 20px;
    min-height: 20px;
    background-repeat: no-repeat;
    background-image: url('https://www.bizzerd.com/bizzerd-b.png');

}

.check-mark-blue {
    font-family: ZapfDingbats, serif;
    color: #809d3f;
    margin-right: 10px;
    display: inline-block;
    width: 20px;
    min-width: 20px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-size: contain;
    height: 20px;
    min-height: 20px;
    background-repeat: no-repeat;
    background-image: url('https://www.bizzerd.com/blueb.png');

}

.enterprise-usp-container {
    display: flex;
    flex-direction: row;
    padding-right: 40px;
    padding-top: 10px;
}

.enterprise-header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
    margin-top: 20px;

}

.home-header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    width: 75%;

}

.home-header-usp-item {
    margin-bottom: 5px;
    margin-top: 5px;
}

.home-header-qr {
    width: 70px;
    height: 70px;
}

.home-header-qr-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;

}

.navigation-person-icon {
    width: 27px;
    margin-left: 10px;
    min-width: 29px;
    cursor: pointer;
}

.navigation-shoppingcart-icon {
    height: 25px;
    margin-left: 10px;
    cursor: pointer;

}

.navigation-hamburger-icon {
    width: 25px;
}

.navigation-unfolded-menu {
    display: none;
    width: 70%;
    z-index: 40;
    background-color: white;
    position: absolute;
    flex-direction: column;
    text-align: left;
    left: 0;
    top: 0;
    padding: 15px;
    font-size: 20px;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2)
}

.navigation-menu-item {
    padding-bottom: 15px;
    padding-top: 15px;
    text-decoration: none;
    color: #334c6e;

}

.navigation-menu-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-bottom: 1px dashed #2d4267;

}

@media only screen and (min-width: 2500px) {
    .home-header-container {
        padding-bottom: 100px;
    }
}

@media only screen and (max-width: 1150px) {
    .scrolled {
        padding-left: 5%;
        padding-right: 5%;
    }

    .enterprise-header-container {
        padding: 20px 3%;
    }

    .product-header {
        padding: 40px 3%;
    }
}

@media only screen and (max-width: 900px) {
    .scrolled {
        padding-left: 5%;
        padding-right: 5%;
        width: 100%;
        left: 0;
    }
}


@media only screen and (max-width: 768px) {
    .product-header {
        flex-direction: column;
        padding: 20px 5%;
        text-align: left !important;
    }

    .product-header-img {
        width: 100%;
    }

    .product-header-right {
        width: 50%;
    }

    .product-block-left {
        text-align: center;
    }

    .product-block-right {
        text-align: center;
    }


    .enterprise-header-buttons {
        width: 100%;
        text-align: center;
    }

    .home-header-left {
        padding-bottom: 0px;
    }

    .enterprise-header-container {
        flex-direction: column !important;

        justify-content: center;
        padding: 10px 5%;
        padding-bottom: 20px;
    }

    .enterprise-header-left {
        width: 100%;
    }

    .product-header-left {
        width: 100%;
    }

    .product-header-right {
        width: 100%;
    }

    .enterprise-header-right {
        padding-top: 40px;
        padding-bottom: 40px;
        width: 100%;
    }

    .home-header-right {
        padding-bottom: 20px;
        align-items: center;
    }

    .navigation-header-button {
        width: 35%;
        min-width: 70px;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .navigation-header-container {
        justify-content: space-between;
        padding: 20px 5%;
        background-color: white;
        width: 100%;
        top: 0;
        border-radius: 0px 0px 10px 10px;
    }

    .home-header-container {
        padding: 40px 7%;
        align-items: center;
        /*flex-direction: column-reverse;*/
    }

    .home-header-qr-container {
        display: none;
    }

    .home-header-buttons {
        width: 100%;
        flex-direction: column;
        margin-top: 0;
        height: 140px;
        justify-content: space-evenly;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 1000px) {
    .navigation-header-button {
        width: 15%;
        min-width: 70px;
    }
}