$_button-background-color: $action-color;
$_button-background-color-hover: lighten($action-color, 5%);
$_button-background-color-active: darken($action-color, 5%);

$_button-background-color-danger-hover: lighten($danger-red, 5%);
$_button-background-color-danger-active: darken($danger-red, 5%);

#{$all-buttons},
.button {
  //appearance: none;
  //background-color: $_button-background-color;
  //border: 0;
  //border-radius: $base-border-radius;
  //cursor: pointer;
  //display: inline-block;
  //font-family: $base-font-family;
  //font-size: 1em;
  //-webkit-font-smoothing: antialiased;
  //font-weight: 600;
  //letter-spacing: .02em;
  //line-height: 1;
  //padding: $small-spacing $medium-spacing;
  //text-align: center;
  //text-decoration: none;
  //text-transform: uppercase;
  //user-select: none;
  //vertical-align: middle;
  //white-space: nowrap;
  //
  //&:focus {
  //  outline: $focus-outline;
  //  outline-offset: $focus-outline-offset;
  //}
  //
  //&:disabled {
  //  cursor: not-allowed;
  //  opacity: 0.5;
  //
  //  &:hover {
  //    background-color: $action-color;
  //  }
  //}
}

.main-button {
  padding: 12px 20px;
  display: block;
  width: fit-content;
  color: #FFF;
  border: 0;
  font-size: 19px;
  border-radius: 5px;
  letter-spacing: 0.12px;
  max-height: 50px;
  white-space: nowrap;
}

button {
  font-family: Outfit-Regular, serif;
  cursor: pointer;

}

.secondary-button {
  padding: 9px 14px;
  display: block;
  white-space: nowrap;
  width: fit-content;

  color: #FFF;
  border: 0;
  font-size: 17px;
  border-radius: 5px;
}

.orange {
  background-color: #f6a723;
}

.aqua {
  background-color: #428fae;
}

.blue {
  background-color: #334c6e;
}

.black {
  background-color: #3d3d3d;
}

.white {
  background-color: #ffffff;
  border: 0.6px solid #d3d9e1;
  color: #334c6e;
}

.orange:hover {
  background-color: #f59900;
}

.aqua:hover {
  background-color: #2a81a4;
}

.blue:hover {
  background-color: #334c6e;
}

.black:hover {
  background-color: #000000;
}

.white:hover {
  background-color: #f6f6f6;
  border: 0.6px solid #91bed0;
}

.step-arrow {
  width: 12px;
  height: 15px;
  margin-left: 40%;
}

@media only screen and (max-width: 768px) {
  .main-button {
    font-size: 24px;
    padding: 12px 5%;
  }
}


.button--primary{
  box-shadow: $shadow-normal;
  color: $white;
  text-transform: capitalize;
  transition: $base-duration--fast $base-timing;
  transition-property: background-color, box-shadow, transform;

  &:focus {
    background-color: $_button-background-color-hover;
    box-shadow: $shadow-hover;
    color: $white;
    transform: translateY(-2px);
  }

  &:hover {
    background-color: $_button-background-color-hover;
    box-shadow: $shadow-hover;
    color: $white;
    transform: translateY(-2px);
  }

  &:active{
    outline: 0;
    background-color: $_button-background-color-active;
    transform: translateY(1px);
    box-shadow: $shadow-active;
  }
}

.button--try {
  border: 0.07em solid $action-color;
  background-color:transparent;
  color:$action-color;
  transition: $base-duration--fast $base-timing;
  transition-property: border-color, color;


  &:focus {
    color: $_button-background-color-hover;
    border-color: $_button-background-color-hover;
  }

  &:hover {
    color: $_button-background-color-hover;
    border-color: $_button-background-color-hover;
  }

  &:active{
    outline: 0;
    }
}


.button--text{
  background-color:transparent;
  border: 0;
  color:$dark-gray;
  padding: 0.4em;
  transition: $base-duration--fast $base-timing;
  transition-property: color;
  text-transform: inherit;

  &:focus {
      background-color: inherit;
      color: $action-color;
  }

  &:hover {
    background-color: inherit;
    color: $action-color;
  }

  &:active {
    outline: 0;
  }
}

.button--danger{
  background-color: $danger-red;
  box-shadow: $shadow-normal;
  color: $white;
  transition: $base-duration--fast $base-timing;
  transition-property: background-color, box-shadow, transform;

  &:focus {
    background-color: $_button-background-color-danger-hover;
    box-shadow: $shadow-hover;
    color: $white;
    transform: translateY(-2px);
  }

  &:hover {
    background-color: $_button-background-color-danger-hover;
    box-shadow: $shadow-hover;
    color: $white;
    transform: translateY(-2px);
  }

  &:active{
    background-color: $_button-background-color-danger-active;
    outline: 0;
    transform: translateY(1px);
    box-shadow: $shadow-active;
  }
}

.button--hamburger {
  background-color:transparent;
  color:$dark-gray;
  // transition: color $base-duration $base-timing;

  &:focus {
      background-color: inherit;
      color: $action-color;
  }

  &:hover {
    background-color: inherit;
    color: $action-color;
  }

  &:active {
    outline: 0;
  }
}
