.step-nav-container {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 80px;
    padding: 15px 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    background-color: #FFFFFF;
}

.step-nav-item {
    color: #324A6D;
    font-weight: bold;
    opacity: 50%;
    cursor: pointer;
}

.selected {
    opacity: 100%;
    color: #f7a823;
}

.old {
    opacity: 100%;
}

.step-flow-container {
    background: linear-gradient(180deg, #EAF2F6 0.11%, #FFF 95.81%);
    min-height: 100vh;
    width: 100%;
}

.step-flow-item-container {
    height: 100%;
    width: 100%;
    position: relative;
    top: 80px;
    padding: 20px 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.foldable-input {
    margin-top: 20px;
    flex-direction: column;
    overflow: hidden;
    transition: all ease-in-out 0.2s;
    display: flex;
    padding: 5px;

    gap: 10px;
}

.step-input-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    max-width: calc(100% - 20px);
    width: 100%;
    align-items: flex-end;
}

.foldable-title {
    color: #324A6D;
    font-family: Outfit-Regular, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.foldable-title-container {
    width: 100%;
    align-items: center;
    display: flex;
    position: relative;
    cursor: pointer;

    flex-direction: row;
    padding: 5px 10px;
    gap: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    background-color: #FFFFFF;
}

.foldable-title-number {
    background-color: #324A6D;
    color: #ffffff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    display: block;
    font-family: Outfit-Regular, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}

.step-select {
    border: 1px solid #dfdee4;
    background-color: #fff;
    padding: 10px 20px 10px 10px;
    border-radius: 3px;
    font-size: 16px;
    width: 100%;
    font-family: Outfit-Regular, serif;
    color: #28395b;
}

.step-select-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.step-flow-horizontal {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    width: 100%;
}

.step-flow-left {
    width: 50%;
}

.step-flow-right {
    width: 50%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
}

.step-button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.step-payment-plan {
    /*min-width: 45%;*/
    /*height: 500px;*/
    /*flex-shrink: 0;*/
    /*align-items: center;*/
    /*border-radius: 10px 10px 200px 200px;*/
    /*background-color: #FFF;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*transition: all ease-in-out 0.2s;*/
    /*padding: 15px;*/
    /*gap: 20px;*/
    /*margin-top: 30px;*/
    /*box-shadow: 0px -6px 34px 0px rgba(0, 0, 0, 0.10);*/
    /*position: relative;*/

    display: flex;
    width: 300px;
    height: 662px;
    padding: 20px 30px;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 10px 10px 100px 100px;
    /*border: 1px solid #F7A941;*/

    background: #FFF;
}

.step-payment-plan-border::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px 10px 100px 100px;
    padding: 1px;
    background: linear-gradient(180deg, #F7A823, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.step-payment-plan-border2::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px 10px 10px 10px;
    padding: 1px;
    background: linear-gradient(180deg, #F7A823, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.step-payment-plan-footer {
    min-width: 100% !important;
    height: 573px;
    position: absolute;
    background-color: #FFF;
    top: 190px;
}

.plan-selected {
    min-width: 55%;
    padding: 10px;
    margin-top: 0px;
    transition: all ease-in-out 0.2s;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to bottom, #f7a82366, #FFF) 1 100%;
    z-index: 99999;
}

.plan-selected-border {
    min-width: 55%;
    z-index: 99999;
    margin-top: 0px;
    transition: all ease-in-out 0.2s;
}

.plan-selected-border::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 5px;
    padding: 1px; /* control the border thickness */
    background: linear-gradient(180deg, #f7a82366, #f7a823);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}

.step-flow-title-container {
    display: flex;
    margin-top: 60px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.step-flow-title-container-left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.template-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px;
}

.flow-button {
    padding: 12px 20px;
    display: flex;
    flex-direction: row;
    width: fit-content;
    color: #FFF !important;
    border: 0;
    font-size: 19px;
    border-radius: 5px;
    letter-spacing: 0.12px;
    max-height: 60px;
    white-space: nowrap;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    text-decoration: none;
}

.orange {
    background-color: #f6a723;
}

.aqua {
    background-color: #428fae;
}

.blue {
    background-color: #334c6e;
}

.black {
    background-color: #3d3d3d;
}

.white {
    background-color: #ffffff;
    border: 0.6px solid #d3d9e1;
    color: #334c6e !important;
}

.flow-text-white {
    color: #334c6e !important;
}

.flow-button-title {
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Outfit-Regular, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.flow-button-sub-title {
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Outfit-Regular, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 0.7;
}

.flow-button-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.feature-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    align-items: center;
}

.feature-title {
    color: #324A6D;
    font-family: Outfit-Regular, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    opacity: 0.6;
}

.payment-user-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.feature-row-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    text-align: left;
}

.payment-user-input-labels {
    text-align: right;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

}

.logo-input-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}

.flow-input-header {
    color: #324A6D;
    font-family: Outfit-Regular, serif;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 700;
    white-space: nowrap;
    line-height: normal;
}

.upload-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;

}

.logo-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 3px;
    border: 1px solid #B2C5CF;
    background: #F3F8FA;
}

.logo-position-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
}

.logo-size-slider {
    width: 100%;
}

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #FFFFFF; /* Set the track color to transparent for IE/Edge */
    border: 1px solid #B2C5CF;
    border-radius: 5px;
}

input[type="range"]::-webkit-slider-runnable-track {
    background: transparent; /* Set the track color to transparent */
}

input[type="range"]::-moz-range-track {
    background: transparent; /* Set the track color to transparent for Firefox */
}

input[type="range"]::-ms-track {
    background: #FFFFFF; /* Set the track color to transparent for IE/Edge */
    border: 1px solid #B2C5CF;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: #F7A823; /* Set the thumb color */
    border-radius: 50%;
    border: 1px solid #BA8135;
    cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #F7A823; /* Set the thumb color for Firefox */
    border-radius: 50%;
    border: 1px solid #BA8135;
    cursor: pointer;
}

input[type="range"]::-ms-thumb {
    width: 20px;
    height: 20px;
    background: #F7A823; /* Set the thumb color for IE/Edge */
    border-radius: 50%;
    border: 1px solid #BA8135;
    cursor: pointer;
}

.logo-size-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5px;
    color: #324A6D;
}

.flow-color-block {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #B2C5CF;
}

.sketch-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99998;
}

.color-input-row-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(50% - 10px);
}

.select-input-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(50% - 10px);
}

.step-flow-button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-grow: 0;
    margin-bottom: 30px;
    margin-top: 30px;
}

.payment-plan-button {
    display: flex;
    padding: 10px 16px;
    flex-direction: column;
    align-self: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #B2C5CF;
    font-family: Outfit-Regular, serif;
    background-color: #FFF;
    font-style: normal;
    line-height: normal;
    color: #324A6D;
    cursor: pointer;
}

.ppb-selected {
    color: #FFF;
    background-color: #f6a723;
    border: 0;
}

.ppb-selected:hover {
    background-color: #f59900 !important;
    border: 0 !important;
}

.payment-plan-button-title {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
}

.payment-plan-button-price {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}


.step-flow-divider {
    width: 100%;
    height: 2px;
    background-color: #324A6D;
    opacity: 0.2;
    margin-top: 10px;
    margin-bottom: 10px;
}

.payment-plan-button-label-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 6px;
}

.payment-plan-button-label {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 9px;
}

.payment-plan-button:hover {
    background-color: #f6f6f6;
    border: 0.6px solid #91bed0;
}

.step-flow-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
}

.step-flow-row-big {
    width: 70%;
    min-height: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 35px;
}

.step-flow-row-big-main {
    padding: 30px;
    color: #324A6D;

}

.row-column {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #324A6D;
}

.pp-header {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.pp-sub {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 0.6;
}

.step-flow-row-small {
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #324A6D;
}

.step-flow-row-label {
    color: #324A6D;
    font-family: Outfit-Regular, serif;
    font-size: 18px;
    font-style: normal;
    padding-right: 15px;

    font-weight: 700;
    line-height: normal;
}

.step-flow-row-label-small {
    color: #324A6D;
    font-family: Outfit-Regular, serif;
    font-size: 14px;
    margin-top: 10px;
    padding-right: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.checkbox-label {
    color: #324A6D;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 5px;
}

.step-link {
    color: #F7A823;
}

.step-flow-divider-small {
    width: 30%;
    height: 2px;
    background-color: #324A6D;
    opacity: 0.2;
    margin-top: 10px;
    margin-bottom: 10px;
}

.flexible-dashboard {
    width: calc(100% - 260px) !important;
    left: 260px !important;
}

.payment-plan-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}


@media only screen and (max-width: 1350px) {

    .step-flow-item-container {
        padding: 20px 5%;
    }
}

@media only screen and (max-width: 1050px) {
    .upload-logo-container {
        flex-direction: column;
        align-items: center;
    }

    .logo-position-container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 768px) {

    .step-flow-row-big {
        width: 45%;
    }

    .step-flow-row-small {
        width: 32.5%;
    }

    .step-flow-divider-small {
        width: 65%;
    }

    .step-flow-row-big-main {
        padding: 2px;

    }

    .payment-plan-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .step-payment-plan {
        height: fit-content;
        border-radius: 10px;
    }

    .foldable-input {
        flex-direction: column;
        align-items: center;
    }


    .step-nav-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        z-index: 99999;
        gap: 5px;
        justify-content: center;
    }

    .step-flow-title-container-left {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .template-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .step-flow-title-container {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }


    .flexible-dashboard {
        width: 100% !important;
    }

    .step-flow-horizontal {
        flex-direction: column-reverse;

    }

    .step-flow-left {
        width: 100%;
    }

    .step-flow-right {
        width: 100%;
    }

    .step-input-row {
        flex-direction: column;
        width: 100%;
        gap: 0px;
    }

    .color-input-row-container {
        width: 100%;
    }

    .select-input-row {
        width: 100%;
    }

    .logo-input {
        flex-direction: column;
        gap: 10px;

    }

    .logo-input-column {
        width: 100%;
        gap: 5px;
    }

    .upload-logo-container {
        flex-direction: row;
    }

    .logo-position-container {
        flex-direction: row;
    }
}

