/************ General system elements*/
$action-color: #F7A823;
$white: #fff;
$light-gray: #F7F7F7;
$lightmedium-gray: #e3e3e3;
$medium-gray: #999;
$darkmedium-gray: #585858;
$dark-gray: #444;
$darkest-gray: #2B2B2B;
$danger-red: #FB6D77;

body {
  background-color: #EAF2F6;
}

.flex--row {
  @include flex-row();
}

.flex--column {
  @include flex-column();
}

.flex--between {
  @include flex-between();
}

.container--full {
  @include grid-container();
  margin: 0px auto;
}

.container--large {
  @include grid-container();
  margin: 0px auto;
  max-width: 1280px;
}

.container--medium {
  @include grid-container();
  margin: 0px auto;
  max-width: 1044px;
}

.container--small {
  @include grid-container();
  margin: 0px auto;
  max-width: 768px;
}

/************ General registration/login elements */
.wrapper {
  @include flex-column();
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  @include grid-column(12);
  @include grid-push(0);
  @include grid-media($media--landscape) {
    @include grid-column(10);
    @include grid-push(1);
  }
}

.form-group {
  margin-top: 1.5em;
}

.form-group__footer {
  margin-top: 2em;
  margin-bottom: 2em;
  @include flex-row();
  justify-content: space-evenly;
}

.wrapper--footer {
  -webkit-box-pack: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  margin-left: 0 !important;
  justify-content: flex-end;
  color: $medium-gray;
  @include grid-column(12);
  @include grid-media($media--tablet) {
    width: 520px;
  }
}

.wrapper--logo {
  -webkit-box-pack: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;

  & img {
    height: 40px;
  }
}

.card {
  width: 100%;
  margin-bottom: 0;
  background-color: #FFF;
  @include flex-column();
  justify-content: flex-start;
  @include grid-media($media--tablet) {
    width: 600px;
  }

  & h2 {
    margin: .1em 0 .2em 0;
  }
}

.card--modal {
  width: 100%;
  margin-bottom: 0;
  margin-left: 20px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  background-color: #FFF;
  border-radius: 10px;
  @include flex-column();
  justify-content: flex-start;
  @include grid-media($media--tablet) {
    max-width: 550px;
    width: 40vw;
    min-width: 545px;
  }

  & h2 {
    margin: .1em 0 .2em 0;
  }
}

.card--graphic {
  -webkit-box-pack: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;

  & img {
    height: 9.375em;
    @include grid-media($media--tablet) {
      height: 12.5em;
    }
  }
}

.input-field {
  margin-bottom: 1em;
}

.input-field__label {
  font-weight: 600;
}

.input-field__input {
  margin-top: .25em;
}

/************ Login page*/
.login--wrapper {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  @include grid-media($media--tablet) {
    @include flex-row();
    width: 450px;
  }
  @include grid-media($media--desktop) {
    width: 450px;
  }
}

.login--left {
  border-radius: .5em .5em .5em .5em;
  padding: 20px 40px;
  width: 100%;
  background-color: $white;
  box-shadow: $shadow-normal;
  -webkit-box-shadow: $shadow-normal;
  @include flex-column();
  justify-content: flex-start;
  @include grid-media($media--tablet) {
    border-radius: .5em .5em .5em .5em;
  }

  & h3 {
    margin: .6em 0 .2em 0;
  }

  p {
    margin: .0 0 2em 0;
  }
}

.login--left .tab--view .tab--view-tab {
  width: 50%;

  &.selected {
    font-weight: bold;
  }

  &.unselected {
    box-shadow: inset 0 -2px $light-gray;

  }
}

.login--left .tab--view .tab--view-tab .tab--tab-name {
  display: block;
  text-align: center;
}

.login--right {
  @include flex-column();
  justify-content: flex-start;

  border-radius: 0 0 .5em .5em;
  padding: 2em 40px 2em 40px;
  width: 100%;
  background-color: $action-color;
  box-shadow: $shadow-normal;
  -webkit-box-shadow: $shadow-normal;
  @include grid-media($media--tablet) {
    border-radius: 0 .5em .5em 0;
  }

  & h3 {
    color: $white;
    font-weight: bold;
    margin: .1em 0 .2em 0;
  }

  & p {
    margin: .0 0 2em 0;
    color: white;
  }
}

.login__forgot-password {
  @include flex-column();
  margin-top: 2em;
  font-weight: 600;
  @include grid-media($media--landscape) {
    @include flex-row();
  }
  @include grid-media($media--tablet) {
    @include flex-column();
  }
  @include grid-media($media--desktop) {
    @include flex-row();
    margin-top: 4em;
  }

  & span:nth-child(2) {
    margin-top: .5em;
    @include grid-media($media--landscape) {
      margin-left: auto;
      margin-top: auto;
    }

    @include grid-media($media--tablet) {
      margin-top: 1em;
      margin-left: 0;
    }

    @include grid-media($media--desktop) {
      margin-left: auto;
      margin-top: auto;
    }
  }
}

.login--footer {
  @include grid-media($media--tablet) {
    width: 670px;
  }
  @include grid-media($media--desktop) {
    width: 870px;
  }
}

/************ Confirmation code section */
.confirmation-code--wrapper {
  @include flex-row();
  @include grid-media($media--tablet) {
  }
}

.confirmation-code--group {
  @include flex-row();
}

.confirmation__input {
  width: 100%;
  border-radius: 0;

  padding-left: 0;
  padding-right: 0;
  text-align: center;

  &:nth-child(1) {
    border-radius: 0.3em 0 0 0.3em;
  }

  &:nth-child(3) {
    border-radius: 0 0.3em 0.3em 0;
  }
}

.confirmation-code--stripe {
  vertical-align: middle;
  display: table-cell;
  font-weight: 200;
  font-size: 2em;
  text-align: center;
  padding: 0 0.5em;
  width: 2em;
}

.alert--error {
  @include flex-row();
  border-radius: 0.3em;
  background-color: #FBE3e4;
  border: 1px solid rgba(215, 43, 63, .4);
  padding: 1em;
  margin-top: 2em;
  margin-bottom: 2em;
  align-items: center;

  & svg {
    min-width: 30px;
    margin-right: 10px;
  }
}

.pop-over-container {
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  background-color: rgba(22, 22, 22, 0.11);
}

.pop-over {
  width: 80%;
  @include grid-media($media--tablet) {
    width: 500px;
  }

  position: relative;
  margin: 0 auto;
  top: 25%;

  border-radius: .5em .5em .5em .5em;
  background-color: white;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

  padding: 20px 40px;

  & h3 {
    padding: .6em 0 .2em 0;
  }

  p {
    padding: .0 0 2em 0;
  }
}

/************ Bedrijfsnaam section */
.bedrijfsnaam--input-field {
  text-align: left;
  text-align: -webkit-left;
}

.bedrijfsnaam--input-field__wrapper {
  @include flex-column();
  @include grid-media($media--landscape) {
    @include flex-row();
  }

  & span {
    font-weight: 600;
  }
}

.bedrijfsnaam--suffix {
  padding-left: .25em;
  @include flex-row();
  align-items: center;
  justify-content: center;
}

.modal {
  & button {
    align-self: center;
  }
}

/************ Dashboard layout elements*/
$dashboard--sidebar-width: 260px;

.dashboard--wrapper {
  @include flex-column();
  height: 100%;
  width: 100%;
}

.cards--flex {
  display: flex;

  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.dashboard--main {
  background-color: #EAF2F6;
  position: relative;
  width: 100%;
  min-height: 100vh;

  @include grid-media($media--desktop) {
    position: relative;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard--main {
    height: 100%;
    @include grid-media($media--desktop) {
      position: relative;
      width: 100%;
    }
  }
}

.dashboard--main-content {
  @include flex-column();
  min-height: 88vh;
  padding: 1em 0;
  padding-top: 0em;
}

.container {
  pointer-events: auto;
  @include grid-container();
  // margin-left: auto;
  // margin-right: auto;
  width: auto;
  position: relative;
  @include grid-media($media--desktop) {
    max-width: 100vw;
  }
}

.container--header {
  @include grid-media($media--desktop) {
  }
}

.cards--wrapper {
  pointer-events: auto;
  @include flex-row();
  flex-wrap: wrap;
  height: fit-content;
  min-height: 100vh;
  justify-content: center;
  @include grid-media($media--desktop) {
    //justify-content: flex-start;
  }

}

@media only screen and (max-width: 768px) {
  .cards--wrapper {
    height: fit-content;
  }
}

.dashboard--nav {
  @include flex-row();
  padding: .5em 0;
  position: relative;
  align-items: center;
  width: auto;
  flex-wrap: wrap;

  &::after {
    content: "";
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: $lightmedium-gray;
    height: 2px;
    position: absolute;
    bottom: 0;
    border-radius: 0.5em;
  }

  & li {
    margin: 0 2em 0.5em 0;
    width: auto;
  }

  > li > a {
    padding: .25em .25em;
    font-weight: normal;

  }

  > li > a.active {
    font-weight: bold;
  }
}

.navigation {
  margin: 0 .75em;
}

.navigation--header {
  position: relative;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}


.dashboard--card {
  position: relative;
  flex-grow: 1;
  color: #334c6e;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 1em;
  border-radius: 10px;
  min-width: 264px;
  max-width: 500px;
  min-height: 300px;
}

.wijzigen {
  min-width: 264px;
  max-width: 500px;

}

.welcome-mail {
  min-width: 70%;
  min-height: 500px;
}

.subscriptionRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.subscriptionTotalPriceRow {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-right: 62px;
  align-items: center;
  padding-bottom: 10px;
}

.subscriptionPaymentRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 20px;
}


.subscriptionCard {
  min-height: 800px;
  height: 800px;
  max-height: 800px;
}

.insightsCard {
  min-width: fit-content;
  max-width: 900px;
  flex-grow: 0;
  max-height: 450px;
  padding: 40px;
}

@media only screen and (max-width: 1050px) {
  .subscriptionCard {
    height: fit-content;
    min-height: fit-content;

    .table--body {
      height: fit-content;
    }
  }
  .flowContainer {
    overflow-y: scroll;
  }
}

.subscriptionTitle {
  font-size: 1.2em;
}

.quantity {
  width: 70px;
  padding-left: 10px;
}

.uitnodigingen {
  min-width: 264px;
  max-width: 500px;

}

.designs {
  min-width: 264px;
  max-width: 500px;


}

.element--card {
  max-width: none;
  min-height: 0;
  width: 100%;
}

.element--card--modal {
  max-width: 1920px;
  min-height: 0;
  width: 66%;
  padding: 20px;
  height: inherit;
}

.card--header {

  @include flex-column();
  position: relative;
  font-weight: bold;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.translation_container {
  position: relative;
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.translation_dropdown {
  width: 30%;
}

.card--main {
  margin-top: 1em;
  margin-bottom: 1em;
  height: fit-content;
}

.card--main--pie {
  margin: auto;
}


.card--footer {
  margin-top: auto;
  margin-bottom: 1em;
  justify-content: space-between;
  align-items: center;
  @include flex-column();

  @include grid-media($media--tablet) {
    @include flex-row();
    align-items: baseline;
  }
}

@media only screen and (max-width: 768px) {
  .card--footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-right: 10px;
  }

  .subscriptionTotalPriceRow {
    margin-right: 10px;
  }
}

.card--footer--koppeling {

  margin-top: auto;
  margin-bottom: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.0em 0.5em;
  @include flex-column();

  @include grid-media($media--tablet) {
    @include flex-row();
    align-items: baseline;
  }
}

.footer--secondary {
  margin-top: 0.75em;
  @include grid-media($media--desktop) {
    margin-left: auto;
    margin-top: 0;
  }
}

.dashboard--sidebar {
  position: relative;
  width: auto;
  background-color: #FFFFFF;
  @include flex-column();
  justify-content: center;
  z-index: 2;
  @include grid-media($media--desktop) {
    position: fixed;
    height: 100%;
    width: $dashboard--sidebar-width;
    @include flex-column();
  }
}

.sidebar--logo {
  margin-bottom: 1em;
  padding: 30px 30px 10px 30px;
  width: 200px;
  align-self: flex-start;
  @include grid-media($media--desktop) {
    width: auto
  }
}

.sidebar--item {
  @include flex-row();
  padding: 1em 1em 1em 1em;
  color: #324A6D;
  cursor: pointer;
  font-family: Outfit-Regular, serif;
  transition: background-color $base-duration--fast $base-timing;
  justify-content: flex-start;

  @include grid-media($media--desktop) {
    justify-content: flex-start;
  }

  &.selected {
    font-weight: 600;
    background-color: #EAF2F6;
  }

  &:hover {
    background-color: #EAF2F6;
    color: #2d4267 !important;

    //color: $white;
  }
}

.sidebar--second {
  margin-top: auto;
  align-self: center;
  margin-bottom: 2em;
  position: absolute;
  top: 0;
  right: 0;
  margin: 30px;
  @include grid-media($media--desktop) {
    position: relative;
    margin-top: auto;
  }
}

.tab--view {
  font-size: 0.9em;
  @include flex-row();
  width: 100%;
  justify-content: space-around;
  margin-top: 1em;
  white-space: nowrap;
  overflow-x: auto;
}

.tab--view-tab {
  padding-bottom: 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
  color: $medium-gray;

  &.selected {
    box-shadow: inset 0 -2px #f7a823;
    color: $dark-gray;
  }

  &:hover {
    color: $dark-gray;
  }
}

.tab--tab-name {
  color: inherit;

  &:hover {
    color: $dark-gray;
  }
}

.dashboard--form-footer {
  justify-content: flex-start;
  margin-top: 4em;

  & button {
    margin-right: 1em;
  }
}

.table--body {
  position: relative;
  margin: 0.75em 0em;
}

.table--body > dl {
  @include flex-column();
  @include grid-media($media--landscape) {
    @include flex-row();
  }
  align-items: normal;
}


.table--body > dl:nth-child(even)::before {
  background-color: rgba(14, 30, 37, .02);
}

dl::before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1em;
  right: -1em;
  pointer-events: none;
  z-index: -1;
}

.table--placeholder {
  color: rgba(14, 30, 37, 0.38);
}

/************ Design tool layout elements */
$toolbar--top-height: 58px;
$toolbar--right-width: 400px;

.toolbar--top {
  @include flex-row();
  background-color: $white;
  padding: 1em 1em;
  box-shadow: $shadow-normal;
  -webkit-box-shadow: $shadow-normal;
  z-index: 2;
  height: $toolbar--top-height;
  position: fixed;
  width: calc(100% - #{$dashboard--sidebar-width});
  align-items: center;
  justify-content: space-between;
}

.toolbar--tools {
  @include flex-row();
  align-items: center;
}

.toolbar--color {
  background: repeating-linear-gradient(to right, #f7a823 0%, #f7a823 100%);
  width: 35px;
  height: 35px;
  border-radius: 0.15em;
}

.toolbar--right {
  top: $toolbar--top-height;
  right: 0;
  position: fixed;
  width: $toolbar--right-width;
  @include flex-row();
  background-color: $dark-gray;
  height: calc(100% - #{$toolbar--top-height});
  z-index: 1;
  font-size: 0.9em;
}

.tool--color-button {
  transition: $base-duration--fast $base-timing;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.tool--button {
  transition: $base-duration--fast $base-timing;
  background-color: inherit;
  margin: 0 0.25em;
  width: 40px;
  padding: .4em .4em;
  font-size: 0.75em;
  border: 0px;
  @include flex-row();
  text-transform: inherit;
  align-items: center;
  height: 40px;

  &.active {
    background-color: $lightmedium-gray;
  }

  &:hover {
    background-color: $light-gray;
  }

  &:active {
    background-color: $lightmedium-gray;
    outline: none;
  }

  &:focus {
    background-color: $lightmedium-gray;
    outline: none;
  }

  & .dropdown--arrow {
    margin-left: 15px;
    margin-bottom: 5px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  & svg {
    width: 28px;
    height: 28px;
  }

  &.save--status {
    cursor: inherit;
    text-transform: inherit;
    font-weight: 300;
    font-style: italic;
    opacity: 0.6;
    width: 100px;

    &:hover {
      background-color: inherit;
    }
  }
}

.tool--button-textcolor {
  @include flex-column;

  .graphic {
    background: repeating-linear-gradient(to right, #f7a823 0%, #f7a823 100%);
    width: 24px;
    height: 6px;
    border-radius: 0.15em;
  }
}

.design--tool-content {
  height: calc(100% - #{$toolbar--top-height});
  overflow: auto;
  left: 0px;
  top: $toolbar--top-height;
  position: relative;
  width: calc(100% - #{$toolbar--right-width});
  outline: none;
}

.design--tool-scrollable {
  min-height: 100%;
  min-width: 750px;
  padding: 1.5em;
  @include flex-row();
  justify-content: center;
  align-items: center;
}

.design--tool-canvas {
  width: 640px;
  height: 1000px;
  @include flex-row();
  justify-content: center;
  align-items: center;
  background-color: white;
}


.right--tab-content {
  @include flex-column();
  height: 100%;
  width: 100%;
  background-color: $darkmedium-gray;
}

.wrapper--scrollable {
  overflow: auto;
  visibility: hidden;
  padding: 1em 0 1em 0;
}

.input-profile {
  input#file-upload-button {
    background-color: #F7A823;
    color: #ededed;
  }
}


.content--pane,
.wrapper--scrollable:hover,
.wrapper--scrollable:focus {
  visibility: visible;
  @include flex-row();
  flex-wrap: wrap;
  color: black;
  overflow-y: auto;
  justify-content: flex-start;
}

.pane--item {
  width: 100%;
  border-radius: .3em;
  margin: 0 .1em;
  padding: 0.75em;
}

.right--tab-nav {
  height: 100%;
  background-color: #585858;
}

.nav--tabs {
  @include flex-column();
  position: relative;
  height: 100%;
  width: 100%;
}

.nav--tabs-button {
  @include flex-column();
  background-color: #444;
  padding: 1em;

  &.active {
    background-color: inherit;
  }

  &.up {
    border-radius: 0 0 0 1em;
  }

  &.bottom {
    border-radius: 1em 0 0 0;
  }

  &.filler {
    height: 100%;
  }
}


/************ Custom layout for design tool */
.dashboard--main-custom {
  position: relative;
  left: $dashboard--sidebar-width;
  width: calc(100% - #{$dashboard--sidebar-width});
  height: 100%;
}

.dashboard--sidebar-custom {
  position: fixed;
  height: 100%;
  width: $dashboard--sidebar-width;
  @include flex-column();
  background-color: $dark-gray;
  z-index: 2;
}

/************* Invite link */
.invitelink--container {
  @include flex-row();
  align-items: center;
  justify-content: space-between;
}

.invitelink {
  margin-right: 1em;
}

.invitelink--message {
  margin-top: 1em;
}

.invitelink--text {
  margin-top: 1em;

  & a {
    padding-left: 0;
  }
}

.sharelink--selector {
  //width: 12em;
  padding: 10px 20px 10px 10px;
  margin-right: 1em;
  border-radius: 3px;
  border: 1px solid #dfdee4;
  color: #2d4267;
  font-size: 16px;
  font-family: Outfit-Regular, serif;
}

/*********** Mail invite */
.invitemail--container {
  @include flex-column();
  margin-bottom: 1em;

  .label--second {
    margin-left: 0px;

    @include grid-media($media--landscape) {
      margin-left: 420px;
    }
  }

  @include grid-media($media--landscape) {
    @include flex-row();
  }

  & input {
    margin-right: 0px;
    margin-bottom: 1em;
    @include grid-media($media--landscape) {
      margin-right: 1em;
      margin-bottom: 0px;
    }
  }
}

.alert--succes {
  @include flex-row();
  border-radius: 0.3em;
  background-color: #e4fbe3;
  border: 1px solid rgba(45, 215, 43, 0.4);
  padding: 1em;
  margin-top: 2em;
  margin-bottom: 2em;
  align-items: center;

  & svg {
    min-width: 30px;
    margin-right: 10px;
  }
}


/********** Collega's wijzigen */
.collega--footer {
  justify-content: flex-start;

  & :nth-child(2) {
    margin-top: .5em;
    @include grid-media($media--tablet) {
      margin-top: 0;
      margin-left: 1.5em;
    }
  }
}

.collega--footer--right {
  justify-content: flex-end;
  margin-right: 1vw;

  & :nth-child(2) {
    margin-left: .5em;
    @include grid-media($media--tablet) {
      margin-top: 0;
      margin-left: 1.5em;
    }
  }
}

.bizzerd--cards-wrapper {
  @include flex-row();
  justify-content: center;
  flex-wrap: wrap;

  margin-top: 0.5em;
  margin-bottom: 0.5em;
  @include grid-media($media--landscape) {
    justify-content: flex-start;
  }

  & .bizzerd {
    border-radius: 8px;
    cursor: pointer;
    margin: 1em;
    box-shadow: 0 2px 4px 0 rgba(14, 30, 37, 0.12);
    -webkit-box-shadow: 0 2px 4px 0 rgba(14, 30, 37, 0.12);
    position: relative;

    transition: $base-duration--fast $base-timing;
    transition-property: box-shadow;

    &:active {
      outline: 0;
    }
  }
}

.bizzerd--wrapper {
  @include flex-column();
  align-items: center;
}


/************ Ontwerp wijzigen */
.placeholder--container {
  @include flex-column;
  align-items: center;
  justify-content: space-around;
  height: 450px;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: .5em;
  padding-right: .5em;
}

/************ Delete button on hover */
.button--hover {
  display: block;
  position: absolute;
  visibility: hidden;
  transition: $base-duration--fast $base-timing;
  transition-property: opacity, visibility, background-color;
  top: 0;
  right: 0;
  opacity: 0;
  color: black;
  margin: 6px;
  padding: 6px;
  background-color: rgba(17, 17, 17, .8);
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
  color: #fff;
  line-height: 1;
  border-radius: 4px;
}

/************ Duplicate button on hover */
.button--hover2 {
  display: block;
  position: absolute;
  visibility: hidden;
  transition: $base-duration--fast $base-timing;
  transition-property: opacity, visibility, background-color;
  top: 0;
  right: 0;
  opacity: 0;
  color: black;
  margin: 6px;
  padding: 6px;
  background-color: rgba(17, 17, 17, .8);
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
  color: #fff;
  line-height: 1;
  border-radius: 4px;
}

.bizzerd:hover {
  .button--hover {
    opacity: 1;
    visibility: visible;

    &:hover {
      background-color: $danger-red;
    }
  }
}

.bizzerd:hover {
  .button--hover2 {
    opacity: 1;
    visibility: visible;

    &:hover {
      background-color: $action-color;
    }
  }
}

/************ Notification banner */
.notification--banner {
  @include flex-row();
  color: $white;
  position: relative;
  min-height: 50px;
  background-color: $action-color;
  justify-content: center;
  align-items: center;
  box-shadow: $shadow-normal;
  -webkit-box-shadow: $shadow-normal;
  padding: 6px 24px;

  & span {
    margin-left: auto;
    max-width: 511px;
    font-size: 0.9em;
    @include grid-media($media--tablet) {
      max-width: 711px;
    }
  }

  & a {
    color: black;
  }

  & .notification--banner-close {
    margin-left: auto;
  }
}

/************ Share Page */
.share--page {
  position: relative;
  background-size: contain;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: #0e1e251f 0px 2px 4px 0px;
}

.share--header {
  position: relative;
  background-color: #F7A823;
  box-shadow: #0e1e251f 0px 2px 4px 0px;

  & span {
    color: #fff;
    position: absolute;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
}

.share--container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.share--card {
  display: block;
  background: #676767;
  color: #fff;
  border-radius: 15px;
  justify-content: center;
  text-align: center;
  box-shadow: #0e1e251f 0px 2px 4px 0px;

  & span {
    display: block;
  }
}

.share--card:hover {
  transform: scale(1.05);
  color: white;
}

.share--back-buton:hover {
  transform: scale(1.05);
}

.share-pop-over-container {
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  background-color: rgba(22, 22, 22, 0.11);
}

.share-pop-over-container form button {
  color: white;
  margin: 20px;

  &:hover {
    transform: scale(1.05);
  }
}


.share-pop-over {
  z-index: 1000;

  position: relative;
  margin: 0 auto;
  top: 25%;

  border-radius: .5em .5em .5em .5em;
  background-color: white;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

  padding: 20px 40px;
  text-align: center;
}

.share--footer {
  background-color: #F7A823;
  bottom: 0px;
  position: absolute;
  width: 100%;
  box-shadow: #0e1e251f 0px 2px 4px 0px;
}

@media only screen and (max-width: 768px) {
  .wijzigen {
    min-width: 90vw;
    max-width: 90vw;
  }
  .dashboard--card {
    position: relative;
    font-size: 0.8em;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px 25px;
    color: #334c6e;
    width: 90vw;
    max-width: 90vw;
    min-height: 350px;
  }

  .element--card--modal {
    max-width: none;
    min-height: 0;
    width: 90% !important;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 4px;
  }
  .modal {
    .card--main {
      margin-bottom: -10em;
    }
  }


}