.pvh-header {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 175px;
  /* UI Properties */
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;

  .pvh-logo {
    width: 175px;
    height: 175px;
  }

}

.pvh-body {
  left: 21.875%;
  width: 56.25%;
  position: relative;
  margin-bottom: 50px;
  text-align: left;

  p {
    font: normal normal normal 18px/27px OpenSans-regular;
  }

  h1 {
    font: normal normal normal 32px/43px OpenSans-bold;
    font-weight: bold;

  }

  h2 {
    font-family: OpenSans-light;
    font-weight: lighter;
    font-size: 22px;

  }

  a {
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 18px/24px OpenSans;
    letter-spacing: 0px;
    color: #6A6A6A;
    opacity: 1;
  }

  .pvh-image-part {
    display: flex;
    flex-direction: row;

    .pvh-image {
      width: 339px;
      height: 235px;
      /* UI Properties */
      opacity: 1;
    }

    .pvh-contact-info {
      margin-left: 60px;

      .pvh-contact-link {
        text-decoration: none;
        color: #000000;
      }
    }
  }
}

.pvh-subheader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  .pvh-calvin-logo {
    width: 10%;
    display: table;
    height: fit-content;
    min-width: 100px;
    margin-bottom: 2px;
  }

  .pvh-hillfiger-logo {
    width: 20%;
    //margin-top: 5px;
    display: table;
    height: fit-content;
    margin-left: 20px;
    min-width: 180px;


  }
}

.pvh-footer {
  height: 530px;
  background: #000000 0% 0% no-repeat padding-box;

  .pvh-list {
    padding-top: 30px;

    p {
      text-align: center;
      font: normal normal normal 20px/34px OpenSans-regular;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
  }

  .pvh-benefits {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    left: 21.875%;
    width: 56.25%;
    position: relative;
    font-family: OpenSans-light;
    font-weight: lighter;
    margin-top: 30px;
    color: #FFFFFF;
  }

  .pvh-benefits2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    left: 21.875%;
    font-family: OpenSans-light;
    font-weight: lighter;
    width: 56.25%;
    position: relative;
    margin-top: 5px;
    color: #FFFFFF;
  }

  .pvh-logo {
    width: 175px;
    height: 175px;
  }
}

@media only screen and (max-width: 768px) {
  .pvh-body {
    left: 5%;
    width: 90%;


    .pvh-image-part {
      flex-direction: column;

      .pvh-contact-info {
        margin-left: 0px;
      }
    }

    h2 {
      text-align: left !important;
    }
  }

  .pvh-footer {
    .pvh-benefits {
      width: 90%;
      left: 5%;
      p {
        font: normal normal normal 18px/27px OpenSans-regular
      }
    }

    .pvh-benefits2 {
      width: 90%;
      left: 5%;
      p {
        font: normal normal normal 18px/27px OpenSans-regular
      }
    }
  }

  .pvh-subheader {
    .pvh-hillfiger-logo {
      margin-top: 0px;
    }
  }
}