.filter-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: solid 1px rgb(187, 193, 201);
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter-header-item-container {
    width: fit-content;
    margin-right: 10px;
}

.filter-header {
    color: #334c6e;
    margin-right: 5px;
}

.filter-category-option {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;

}

.filter-header-container select {
    border: 0px;
    font-weight: bold;
    color: #334c6e;

    font-size: 16px;
}

.filter-header-container {
    width: 80%;
    height: 40px;
    display: flex;
    max-width: 1920px;
    margin-bottom: 15px;
    padding-left: 5%;
    padding-right: 15%;
}

.filter-header-item {

}

.filter-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    padding-top: 5px;
    text-align: left;
    padding-bottom: 5px;
}

.filter-qr-container {
    margin-left: 25px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 12px;
    background-color: rgb(242, 242, 242);
    border-radius: 5px;
}

.pagination-ball {
    background-color: #428096;
    border-radius: 50%;
    color: white;
    height: 30px;
    line-height: 30px;
    min-width: 30px;
    user-select: none;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
}

.pagination-ball-selected {
    background-color: #df8f08;
    border-radius: 50%;
    color: white;
    height: 30px;
    line-height: 30px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;;
    user-select: none;
    min-width: 30px;
    text-align: center;
}

.pagination-container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    user-select: none;
    justify-content: space-evenly;
    padding-bottom: 20px;

}

.pagination-arrow {
    width: 15px;
    margin-left: 5px;
    cursor: pointer;
    height: 10px;
    user-select: none;

}


@media only screen and (max-width: 768px) {

    .filter-category-option {
        text-align: center;

    }

    .pagination-container {
        width: 70%;
        align-items: center;
    }

    .filter-header-container {
        flex-direction: column;
        margin-bottom: 30px;
        align-items: center;
        height: 100px;
        padding-left: 5%;
        padding-right: 5%;
    }

    .filter-header-item-container {
        margin-bottom: 10px;
    }

}