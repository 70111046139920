ul,
ol {
  list-style-type: none;
  margin: 0px 0px 0px 0px;
  padding: 0;
}

dl {
  margin: 0;
  position: relative;
  @include flex-row();
  align-items: center;
  z-index: 0;
  padding: 0px 0.5em;
}

dt {
  font-weight: 600;
  margin: 0;
  min-width: 14em;
}

dd {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

li {
  margin: 0 0 0.75em;
}

li a {
  color: $base-font-color;
  padding: 0;
}

li a img {
  margin-left: -6px;
  margin-right: 15px;
  margin-bottom: -2px;
}
